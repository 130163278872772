import React from "react";
import PageContainer from "../../../components/PageContainer";

import UpdateUserForm from "./components/UpdateUserForm";

function Profile() {
  return (
    <PageContainer>
      <UpdateUserForm></UpdateUserForm>
    </PageContainer>
  );
}

export default Profile;
