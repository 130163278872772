import stripHTML from "string-strip-html";
import jwt from "jwt-decode";

export const ellipses = (text: string, length = 140) => {
  const txt = stripHTML(text);
  if (length <= 0) {
    return "";
  }
  if (txt.length <= length) {
    return txt;
  }
  return `${txt.slice(0, length)}...`;
};

export const tknExpired = () => {
  const tkn = sessionStorage.getItem("token");
  if (tkn === null) return true;
  const decoded: { exp: number } = jwt(tkn);
  const time = Math.ceil(Date.now() / 1000);
  if (time >= decoded.exp) return true;
  return false;
};
