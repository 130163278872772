import actions from "../actions/actions";
import { IMeta } from "../../types";

const initialState = {
  article_types: [],
  project_statuses: [],
  resource_types: [],
  staff_types: [],
  districts: [],
} as IMeta;

export default (
  state = initialState as IMeta,
  action: { type: string; payload?: any; meta?: any }
) => {
  switch (action.type) {
    case actions.getMeta + "_FULFILLED":
      return {
        ...state,
        [`${action.meta}`]: action.payload.data,
      };

    default:
      return state;
  }
};
