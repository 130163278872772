import React from "react";
import PageContainer from "../../../components/PageContainer";
import MemberForm from "./components/Form";

function CreateMember() {
  return (
    <PageContainer>
      <MemberForm />
    </PageContainer>
  );
}

export default CreateMember;
