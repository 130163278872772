import React, { useEffect } from "react";
import NavBar from "./NavBar";
// @ts-ignore
import styled from "styled-components";
import { useHistory, Link, useParams } from "react-router-dom";
import { theme } from "../theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faPlus } from "@fortawesome/free-solid-svg-icons";
import { useSelector, useDispatch } from "react-redux";
import { IState } from "../Services/types";
import { setAuth } from "../Services/redux/actions/auth";
import Api from "../Services/api";
import jwt from "jwt-decode";
import { tknExpired } from "../Services/helpers";

function PageContainer({ children, addBtn }: Props) {
  const history = useHistory();

  const basePage = history.location.pathname.split("/")[1];
  const auth = useSelector((state: IState) => state.auth);
  const dispatch = useDispatch();

  const setLogin = () => {
    const tkn = sessionStorage.getItem("token");
    if (!tkn) return;
    const decoded: { sub: number } = jwt(tkn);

    Api.getUser(Number(decoded.sub), tkn)
      .then((res) => {
        const user = res.data;
        dispatch(setAuth({ authenticated: true, user }));
      })
      .catch(() => {
        history.push("/");
      });
  };
  const checkLogin = () => {
    if (tknExpired()) {
      sessionStorage.clear();
      history.push("/?tkn");
      return;
    }
    if (!auth.authenticated) setLogin();
  };

  useEffect(() => {
    checkLogin();
  }, [checkLogin, history.location.pathname]);

  const onBack = () => {
    history.goBack();
  };

  return (
    <div>
      <NavBar />
      <ContentWrapper>
        <BreadCrumb>
          <BackBtn onClick={onBack} className="shadow">
            <FontAwesomeIcon icon={faChevronLeft} />
          </BackBtn>
          {addBtn && (
            <Link to={`/${basePage}/add`}>
              <AddBtn className="shadow">
                <FontAwesomeIcon icon={faPlus} />
              </AddBtn>
            </Link>
          )}
        </BreadCrumb>
        <div>{children}</div>
      </ContentWrapper>
    </div>
  );
}

type Props = {
  children?: any;
  addBtn?: boolean;
};

export default PageContainer;

const ContentWrapper = styled.div`
  padding-left: 19rem;
  padding-top: 5rem;
  padding-right: 2rem;
  width: 100%;
  min-height: calc(100vh - 3.5rem);
`;

const BreadCrumb = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.5rem 0rem 2rem;
`;
const BackBtn = styled.div`
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ededed;
  border-radius: 50%;
  cursor: pointer;
  color: gray;
`;

export const AddBtn = styled.div`
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${theme.primaryColor.darker};
  border-radius: 50%;
  cursor: pointer;
  color: white;
`;
