import React, { useState, useEffect } from "react";
import { Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import {
  FormContainer,
  Field,
  FormFooter,
  SubmitButton,
  CloudinaryField,
} from "../../../../components/Form";
import { useDispatch, useSelector } from "react-redux";
import { Snackbar } from "@material-ui/core";
import api from "../../../../Services/api";
import Alert from "../../../../components/Alert";
import { getDonors } from "../../../../Services/redux/actions/data";
import { useParams } from "react-router-dom";
import { IState } from "../../../../Services/types";

function PartnerForm({ update }: Props) {
  const params = useParams();

  const dispatch = useDispatch();

  const partners = useSelector((state: IState) => state.data.donors);

  const [error, setError] = useState("");

  const [snack, setSnack] = useState({ open: false, message: "Partner Saved" });

  const [initialValues, setInitialValues] = useState({
    name: "",
    picture: "",
    picture_code: "",
    website: "",
  });

  useEffect(() => {
    if (update) {
      // @ts-ignore
      const p = partners.find((p) => p.id === Number(params.id));
      if (p)
        setInitialValues({
          name: p.name,
          picture: p.picture.path,
          picture_code: p.picture.code,
          website: p.website,
        });
    }
  }, [params, partners]);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
    picture: Yup.string().required(),
    website: Yup.string().required(),
  });

  const onSuccess = (resetForm: Function) => {
    dispatch(getDonors());
    setSnack({ ...snack, open: true });
    resetForm();
  };
  const onSubmit = (
    values: IValues,
    { setSubmitting, resetForm }: FormikHelpers<IValues>
  ) => {
    setSubmitting(true);
    setError("");
    if (update) {
      api
        // @ts-ignore
        .updatePartner(values, params.id)
        .then(() => {
          onSuccess(resetForm);
        })
        .catch((e) => {
          setError("Failed To Update Partner. Please Try Again");
        })
        .finally(() => {
          setSubmitting(false);
        });
      return;
    }
    api
      .addPartner(values)
      .then(() => {
        onSuccess(resetForm);
      })
      .catch((e) => {
        setError("Failed To Add Partner. Please Try Again");
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <FormContainer className="shadow p-5">
      <div className="mb-4">{update ? "Update Partner" : "New Partner"}</div>

      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <form>
            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              open={snack.open}
              onClose={() => setSnack({ ...snack, open: false })}
              message={snack.message}
            />
            <Alert message={error} />
            <CloudinaryField
              width="20%"
              name="picture"
              values={values}
              setFieldValue={setFieldValue}
            />

            <Field
              value={values.name}
              name="name"
              label="Name"
              type="text"
              onChange={handleChange}
              touched={touched.name}
              error={errors.name}
              placeholder="Enter Partner Name"
            ></Field>

            <Field
              value={values.website}
              name="website"
              label="Website"
              type="text"
              onChange={handleChange}
              touched={touched.website}
              error={errors.website}
              placeholder="https://csona.org"
            ></Field>
            <FormFooter>
              <SubmitButton
                isSubmitting={isSubmitting}
                handleSubmit={handleSubmit}
              />
            </FormFooter>
          </form>
        )}
      </Formik>
    </FormContainer>
  );
}

type Props = {
  update?: boolean;
};
type IValues = {
  name: string;
  picture: string;
  picture_code: string;
  website: string;
};

export default PartnerForm;
