import React from "react";
import PageContainer from "../../../../../../components/PageContainer";

import SliderForm from "../Create/components/Form";

function UpdateSlider() {
  return (
    <PageContainer addBtn>
      <SliderForm update />
    </PageContainer>
  );
}

export default UpdateSlider;
