import React from "react";
import PageContainer from "../../../components/PageContainer";
import MemberForm from "../Create/components/Form";

function UpdateMember() {
  return (
    <PageContainer addBtn>
      <MemberForm update />
    </PageContainer>
  );
}

export default UpdateMember;
