import React, { useState, useEffect } from "react";
import { IArticle, IState, IProject } from "../../../Services/types";
import { Dialog, Modal } from "@material-ui/core";
import { LazyLoadImage } from "react-lazy-load-image-component";
// @ts-ignore
import styled from "styled-components";
import PageContainer from "../../../components/PageContainer";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { theme } from "../../../theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import Parser from "html-react-parser";

function ViewProject() {
  const [imageLoaded, setImageLoaded] = useState(false);
  const params = useParams();
  const projects = useSelector((state: IState) => state.data.projects);
  const [project, setProject] = useState({} as IProject);
  useEffect(() => {
    // @ts-ignore
    const a = projects.find((a) => a.id === Number(params.id));
    if (a) setProject(a);
  }, [params, projects]);
  return (
    <PageContainer addBtn>
      <Img>
        {!imageLoaded && (
          <img src="https://res.cloudinary.com/csona/image/upload/v1591092657/placeholder-image10_om6ciy.jpg" />
        )}
        <img
          className={imageLoaded ? "" : "hidden"}
          src={project?.picture?.path}
          onLoad={() => setImageLoaded(true)}
        />
      </Img>
      <Title className="my-2">
        <span>{project?.status?.status} | </span>
        {project?.title}
      </Title>
      <DateCreated>
        <FontAwesomeIcon icon={faClock} className="mr-2" />
        {moment(project?.created_at).fromNow()}
      </DateCreated>
      <p className="my-3 ql-editor">{project && Parser(project.about || "")}</p>
    </PageContainer>
  );
}

export default ViewProject;

const Img = styled.div`
  width: 50%;
`;

const Title = styled.div`
  color: ${theme.primaryColor.default};
  span {
    color: gray;
  }
`;

const DateCreated = styled.div`
  font-size: 0.8rem;
  color: gray;
`;
