import React from "react";

function Alert({ message }: Props) {
  return (
    <>
      {message.length > 0 && (
        <div
          className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
          role="alert"
        >
          <strong className="font-bold mr-2">Error!</strong>
          <span className="block sm:inline">{message}</span>
        </div>
      )}
    </>
  );
}

type Props = {
  message: string;
};
export default Alert;
