import React, { useState, useEffect } from "react";
import { Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import {
  FormContainer,
  Field,
  FormFooter,
  SubmitButton,
  RadioGroup,
  TextArea,
  CloudinaryField,
} from "../../../../components/Form";
import { useSelector, useDispatch } from "react-redux";
import { IState } from "../../../../Services/types";
import { Snackbar } from "@material-ui/core";
import api from "../../../../Services/api";
import Alert from "../../../../components/Alert";
import { getArticles } from "../../../../Services/redux/actions/data";
import { useParams } from "react-router-dom";

function ArticleForm({ update }: Props) {
  const articleTypes = useSelector((state: IState) => state.meta.article_types);

  const articles = useSelector((state: IState) => state.data.articles);

  const params = useParams();

  const dispatch = useDispatch();

  const [error, setError] = useState("");

  const [snack, setSnack] = useState({ open: false, message: "Article Saved" });

  const [initialValues, setinitialValues] = useState({
    title: "",
    body: "",
    authors: "",
    picture: "",
    picture_code: "",
    type_id: 0,
  });

  useEffect(() => {
    if (update) {
      // @ts-ignore
      const a = articles.find((a) => a.id === Number(params.id));
      if (a)
        setinitialValues({
          title: a.title,
          body: a.body,
          authors: a.authors,
          picture: a.picture.path,
          picture_code: a.picture.code,
          type_id: a.type_id,
        });
      return;
    }
    setinitialValues({
      ...initialValues,
      type_id: articleTypes.length > 0 ? articleTypes[0].id : 0,
    });
  }, [articleTypes, params, articles]);

  const editorModules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      ["clean"],
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Required"),
    body: Yup.string().required("Required"),
    picture: Yup.string().required(),
  });

  const onSuccess = (resetForm: Function) => {
    dispatch(getArticles());
    setSnack({ ...snack, open: true });
    resetForm();
  };

  const onSubmit = (
    values: IValues,
    { setSubmitting, resetForm }: FormikHelpers<IValues>
  ) => {
    setSubmitting(true);
    setError("");
    if (update) {
      api
        // @ts-ignore
        .updateArticle(values, params.id)
        .then(() => {
          onSuccess(resetForm);
        })
        .catch((e) => {
          setError("Failed To Update Article. Please Try Again");
        })
        .finally(() => {
          setSubmitting(false);
        });
      return;
    }
    api
      .addArticle(values)
      .then(() => {
        onSuccess(resetForm);
      })
      .catch((e) => {
        setError("Failed To Add Article. Please Try Again");
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <FormContainer className="shadow p-5">
      <div className="mb-4">{update ? "Update Article" : "New Article"}</div>

      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <form>
            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              open={snack.open}
              onClose={() => setSnack({ ...snack, open: false })}
              message={snack.message}
            />
            <Alert message={error} />
            <CloudinaryField
              name="picture"
              values={values}
              setFieldValue={setFieldValue}
            />

            <RadioGroup
              label="Type"
              name="type_id"
              values={values}
              setFieldValue={setFieldValue}
              displayField="type"
              options={articleTypes}
            />
            <Field
              value={values.authors}
              name="authors"
              label="Authors"
              type="text"
              onChange={handleChange}
              touched={touched.authors}
              error={errors.authors}
              placeholder="Enter Article Authors"
            ></Field>
            <Field
              value={values.title}
              name="title"
              label="Title"
              type="text"
              onChange={handleChange}
              touched={touched.title}
              error={errors.title}
              placeholder="Enter Article Title"
            ></Field>
            <TextArea
              label="Body"
              values={values}
              name="body"
              setFieldValue={setFieldValue}
              editorModules={editorModules}
            />

            <FormFooter>
              <SubmitButton
                isSubmitting={isSubmitting}
                handleSubmit={handleSubmit}
              />
            </FormFooter>
          </form>
        )}
      </Formik>
    </FormContainer>
  );
}

type Props = {
  update?: boolean;
};

type IValues = {
  title: string;
  body: string;
  authors: string;
  picture: string;
  picture_code: string;
  type_id: number;
};

export default ArticleForm;
