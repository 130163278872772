import React from "react";
// @ts-ignore
import styled from "styled-components";
import { theme } from "../../theme";
import { Link, useHistory } from "react-router-dom";
import { menu } from "./SideBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector, useDispatch } from "react-redux";
import { IMenuItem, IState } from "../../Services/types";
import { setAuth } from "../../Services/redux/actions/auth";

function TopBar() {
  const dispatch = useDispatch();
  const history = useHistory();
  const auth = useSelector((state: IState) => state.auth);
  const basePage = history.location.pathname.split("/")[1];
  const urlArray = history.location.pathname.split("/");
  const icon = menu.find(
    (m) => m.url.split("/")[1].toLowerCase() === basePage.toLowerCase()
  )?.icon;

  const getFirstLetter = (text: string) => {
    return text.length > 0 ? text[0] : "";
  };

  const names = auth.authenticated ? auth.user.name.split(" ") : [];

  const onLogout = (i: IMenuItem) => {
    sessionStorage.removeItem("token");
    history.push("/");
    dispatch(setAuth({ authenticated: false, user: {} }));
  };
  return (
    <Wrapper className="shadow">
      <PageLabel>
        <FontAwesomeIcon icon={icon} className="text-xl mr-4" />
        {urlArray[1]}
      </PageLabel>
      <UserName>
        {names.map((n) => getFirstLetter(n))}
        <DropDown className="shadow">
          <DropDownItem
            item={{ name: "Profile", url: "/profile" }}
            onClick={() => history.push("/users/profile")}
          />
          <DropDownItem
            item={{ name: "Logout", url: "/" }}
            onClick={onLogout}
          />
        </DropDown>
      </UserName>
    </Wrapper>
  );
}

export default TopBar;

const DropDownItem = ({ item, onClick }: { item: IMenuItem; onClick: any }) => (
  <DropDownItemWrapper onClick={() => onClick(item)}>
    {item.name}
  </DropDownItemWrapper>
);

const Wrapper = styled.div`
  border-top: 4px solid ${theme.primaryColor.darker};
  display: flex;
  z-index: 999;
  align-items: center;
  position: fixed;
  padding-left: 18rem;
  padding-right: 2rem;
  justify-content: space-between;
  top: 0;
  left: 0;
  right: 0;
  height: 3.5rem;
  background: white;
`;

const PageLabel = styled.div`
  color: ${theme.primaryColor.darker};
  text-transform: uppercase;
`;

const DropDownItemWrapper = styled.div`
  border-bottom: 1px solid #ededed;
  padding: 0.5rem;
  font-size: 1rem;
  text-transform: capitalize;
  color: ${theme.primaryColor.default};
  &:hover {
    color: ${theme.primaryColor.default};
    background: #ededed;
  }
`;
const DropDown = styled.div`
  border-top: 2px solid ${theme.primaryColor.default};
  background: white;
  position: absolute;
  top: 50px;
  visibility: hidden;
  float: left;
  min-width: 8rem;
  left: -200%;
  z-index: 1000;
  -webkit-transform: translate3d(0, -1rem, 0);
  transform: translate3d(0, -1rem, 0);
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
  display: block;
  opacity: 0;
  border-radius: 0;
  padding: 0;
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.25, 0.75, 0.75, 1),
    opacity 0.3s cubic-bezier(0.25, 0.75, 0.75, 1),
    visibility 0.3s cubic-bezier(0.25, 0.75, 0.75, 1);
  transition: transform 0.3s cubic-bezier(0.25, 0.75, 0.75, 1),
    opacity 0.3s cubic-bezier(0.25, 0.75, 0.75, 1),
    visibility 0.3s cubic-bezier(0.25, 0.75, 0.75, 1);
`;

const UserName = styled.div`
  text-transform: uppercase;
  height: 2.5rem;
  width: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${theme.primaryColor.default};
  color: white;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  &:hover ${DropDown} {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
`;
