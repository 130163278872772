import React, { useEffect } from "react";
import PageContainer from "../../../components/PageContainer";
import { useSelector, useDispatch } from "react-redux";
import { IState } from "../../../Services/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import MaterialTable from "material-table";
import { ellipses } from "../../../Services/helpers";
import { useHistory } from "react-router-dom";
import swal from "sweetalert2";
import { getUsers } from "../../../Services/redux/actions/data";
import api from "../../../Services/api";

function UsersList() {
  const dispatch = useDispatch();
  const users = useSelector((state: IState) => state.data.users);

  useEffect(() => {
    if (users.length === 0) dispatch(getUsers());
  }, []);
  const data = {
    columnDefs: [
      { title: "Name", field: "name" },
      { title: "Email", field: "email" },
      { title: "Created", field: "created_at" },
    ],
    rowData: users,
    actions: [
      {
        icon: () => (
          <FontAwesomeIcon icon={faTrash} className="text-base text-red-600" />
        ),
        tooltip: "Delete User",
        // @ts-ignore
        onClick: (event, rowData) => onDelete(rowData?.id),
      },
    ],
    options: {
      actionsColumnIndex: -1,
    },
  };

  const onDelete = (id: number) => {
    swal
      .fire({
        type: "warning",
        title: "Are you sure?",
        text: "You will not be able to recover this record!",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, keep it",
      })
      .then((result: any) => {
        if (result.value) {
          api
            .deleteResource("users", id)
            .then(() => {
              swal.fire("Deleted!", "User deleted.", "success");
              dispatch(getUsers());
            })
            .catch(() => {
              swal.fire("Failed to delete", "Failed to delete user.", "error");
            });

          return;
        }
      });
  };
  return (
    <PageContainer addBtn>
      <MaterialTable
        style={{ padding: "1rem" }}
        title="Users"
        columns={data.columnDefs}
        data={data.rowData}
        actions={data.actions}
        options={data.options}
      />
    </PageContainer>
  );
}

export default UsersList;
