import React, { useState, useEffect } from "react";
import { Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import {
  FormContainer,
  Field,
  FormFooter,
  SubmitButton,
  RadioGroup,
  TextArea,
  CloudinaryField,
} from "../../../../components/Form";
import { useSelector, useDispatch } from "react-redux";
import { IState } from "../../../../Services/types";
import { Snackbar } from "@material-ui/core";
import api from "../../../../Services/api";
import Alert from "../../../../components/Alert";
import { getProjects } from "../../../../Services/redux/actions/data";
import { useParams } from "react-router-dom";

function ProjectForm({ update }: Props) {
  const params = useParams();

  const projects = useSelector((state: IState) => state.data.projects);

  const projectStatuses = useSelector(
    (state: IState) => state.meta.project_statuses
  );

  const dispatch = useDispatch();

  const [error, setError] = useState("");

  const [snack, setSnack] = useState({ open: false, message: "Project Saved" });

  const [initialValues, setinitialValues] = useState({
    title: "",
    about: "",
    picture: "",
    picture_code: "",
    status_id: 0,
  });

  useEffect(() => {
    if (update) {
      // @ts-ignore
      const p = projects.find((p) => p.id === Number(params.id));
      if (p)
        setinitialValues({
          title: p.title,
          about: p.about,
          picture: p.picture.path,
          picture_code: p.picture.code,
          status_id: p.status_id,
        });
      return;
    }
    setinitialValues({
      ...initialValues,
      status_id: projectStatuses.length > 0 ? projectStatuses[0].id : 0,
    });
  }, [projectStatuses, projects, params]);

  const editorModules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      ["clean"],
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Required"),
    about: Yup.string().required("Required"),
    picture: Yup.string().required(),
  });

  const onSuccess = (resetForm: Function) => {
    dispatch(getProjects());
    setSnack({ ...snack, open: true });
    resetForm();
  };

  const onSubmit = (
    values: IValues,
    { setSubmitting, resetForm }: FormikHelpers<IValues>
  ) => {
    setSubmitting(true);
    setError("");
    if (update) {
      api
        // @ts-ignore
        .updateProject(values, params.id)
        .then(() => {
          onSuccess(resetForm);
        })
        .catch((e) => {
          setError("Failed To Update Project. Please Try Again");
        })
        .finally(() => {
          setSubmitting(false);
        });
      return;
    }
    api
      .addProject(values)
      .then(() => {
        onSuccess(resetForm);
      })
      .catch((e) => {
        setError("Failed To Add Project. Please Try Again");
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <FormContainer className="shadow p-5">
      <div className="mb-4">{update ? "Update Project" : "New Project"}</div>

      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <form>
            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              open={snack.open}
              onClose={() => setSnack({ ...snack, open: false })}
              message={snack.message}
            />
            <Alert message={error} />
            <CloudinaryField
              name="picture"
              values={values}
              setFieldValue={setFieldValue}
            />

            <RadioGroup
              label="Status"
              name="status_id"
              values={values}
              setFieldValue={setFieldValue}
              displayField="status"
              options={projectStatuses}
            />
            <Field
              value={values.title}
              name="title"
              label="Title"
              type="text"
              onChange={handleChange}
              touched={touched.title}
              error={errors.title}
              placeholder="Enter Project Title"
            ></Field>
            <TextArea
              label="About"
              values={values}
              name="about"
              setFieldValue={setFieldValue}
              editorModules={editorModules}
            />

            <FormFooter>
              <SubmitButton
                isSubmitting={isSubmitting}
                handleSubmit={handleSubmit}
              />
            </FormFooter>
          </form>
        )}
      </Formik>
    </FormContainer>
  );
}

type Props = {
  update?: boolean;
};
type IValues = {
  title: string;
  about: string;
  picture: string;
  picture_code: string;
  status_id: number;
};

export default ProjectForm;
