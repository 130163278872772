export default {
  resetError: "RESET_ERROR",
  getDetails: "GET_DETAILS",
  getProjects: "GET_PROJECTS",
  getArticles: "GET_ARTICLES",
  getResources: "GET_RESOURCES",
  getStaff: "GET_STAFF",
  getDonors: "GET_DONORS",
  getUsers: "GET_USERS",
  getMembers: "GET_MEMBERS",
  setAuth: "SET_AUTH",
  getMeta: "GET_META",
};
