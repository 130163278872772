import actions from "../actions/actions";
import { IAuth } from "../../types";

const initialState = {
  authenticated: false,
  user: {},
} as IAuth;

export default (
  state = initialState as IAuth,
  action: { type: string; payload?: any; meta?: any }
) => {
  switch (action.type) {
    case actions.setAuth:
      return {
        ...action.payload,
      };
    default:
      return state;
  }
};
