import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  FormContainer,
  FormFooter,
  SubmitButton,
  Field,
  FieldContainer,
} from "../../../../components/Form";
import { Formik, FormikHelpers, ErrorMessage } from "formik";
import Alert from "../../../../components/Alert";
import { Snackbar } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { IState } from "../../../../Services/types";
import * as Yup from "yup";
// @ts-ignore
import styled from "styled-components";
import { theme } from "../../../../theme";
import { getResources } from "../../../../Services/redux/actions/data";
import { useParams } from "react-router-dom";
import api from "../../../../Services/api";

function DocForm({ update, restype }: Props) {
  const params: { id: string } = useParams();

  const [percentage, setpercentage] = useState(0);

  const resources = useSelector((state: IState) => state.data.resources);

  const [error, setError] = useState("");

  const dispatch = useDispatch();
  const types = useSelector((state: IState) => state.meta.resource_types);

  const [initialValues, setInitialValues] = useState({
    file: "",
    name: "",
    code: null as string | null,
    type_id: restype,
  });

  const [snack, setSnack] = useState({
    open: false,
    message: "Resource Save",
  });

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
    file: Yup.string().required(),
  });

  useEffect(() => {
    if (update) {
      const r = resources.find((r) => r.id === Number(params.id));
      if (r)
        setInitialValues({
          file: r.path,
          name: r.name,
          type_id: r.type_id,
          code: r.code,
        });
      return;
    }
  }, [types, params, resources]);

  const onSuccess = (resetForm: Function) => {
    dispatch(getResources());
    setSnack({ ...snack, open: true });
    resetForm();
  };

  const onSave = (
    values: IValues,
    { setSubmitting, resetForm }: FormikHelpers<IValues>
  ) => {
    if (update) {
      api
        .updateResource(
          {
            name: values.name,
            type_id: values.type_id,
            path: values.file,
            code: values.code,
          },
          Number(params.id)
        )
        .then(() => {
          onSuccess(resetForm);
        })
        .catch(() => {
          setError("Failed To Update Resource. Please Try Again");
        })
        .finally(() => setSubmitting(false));
      return;
    }

    const config = {
      onUploadProgress: function (progressEvent: {
        loaded: number;
        total: number;
      }) {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setpercentage(percentCompleted);
      },
      headers: {
        token: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    };

    const formData = new FormData();

    formData.append("file", values.file);
    formData.append("name", values.name);
    formData.append("type_id", `${values.type_id}`);

    axios
      .post(`${process.env.REACT_APP_API}/resources/upload`, formData, config)
      .then((res) => {
        onSuccess(resetForm);
      })
      .catch((err) => {
        setError("Failed To Add Resource. Please Try Again");
      })
      .finally(() => setSubmitting(false));
  };
  return (
    <FormContainer>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={onSave}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <form>
            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              open={snack.open}
              onClose={() => setSnack({ ...snack, open: false })}
              message={snack.message}
            />
            <Alert message={error} />

            {update ? (
              <input name="file" value={values.file} type="hidden" />
            ) : (
              <FieldContainer>
                <input
                  className="mt-5"
                  type="file"
                  name="file"
                  onChange={(event) => {
                    // @ts-ignore
                    setFieldValue("file", event.currentTarget.files[0]);
                  }}
                ></input>
                <div className="text-red-400 text-sm">
                  <ErrorMessage name="file" />
                </div>
              </FieldContainer>
            )}

            <Field
              value={values.name}
              name="name"
              label="Name"
              type="text"
              onChange={handleChange}
              touched={touched.name}
              error={errors.name}
              placeholder="Enter Name"
            ></Field>

            <input type="hidden" name="type_id" value={values.type_id} />
            <Progress per={percentage} />
            <FormFooter>
              <SubmitButton
                isSubmitting={isSubmitting}
                handleSubmit={handleSubmit}
              />
            </FormFooter>
          </form>
        )}
      </Formik>
    </FormContainer>
  );
}

type Props = {
  update?: boolean;
  restype: number;
};

type IValues = {
  name: string;
  file: any;
  code: string | null;
  type_id: number;
};

export default DocForm;

const Progress = ({ per }: { per: number }) => {
  return (
    <PrgContainer per={per} className="my-5">
      <div></div>
    </PrgContainer>
  );
};

const PrgContainer = styled("div")<any>`
  height: 0.5rem;
  width: 50%;
  border: 1px solid ${theme.primaryColor.default};
  border-radius: 4px;
  div {
    width: ${(props: any) => `${props.per}%`};
    height: 100%;
    background: ${theme.primaryColor.default};
  }
`;
