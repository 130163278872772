import React, { useState, useEffect } from "react";
import { Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import {
  FormContainer,
  Field,
  FormFooter,
  SubmitButton,
  CloudinaryField,
} from "../../../../components/Form";
import { useDispatch, useSelector } from "react-redux";
import { Snackbar } from "@material-ui/core";
import api from "../../../../Services/api";
import Alert from "../../../../components/Alert";
import { getResources } from "../../../../Services/redux/actions/data";
import { IState } from "../../../../Services/types";
import { useParams } from "react-router-dom";

function ImageForm({ update }: Props) {
  const params: { id: string } = useParams();

  const resources = useSelector((state: IState) => state.data.resources);

  const dispatch = useDispatch();

  const types = useSelector((state: IState) => state.meta.resource_types);

  const [error, setError] = useState("");
  const [snack, setSnack] = useState({
    open: false,
    message: "Resource Saved",
  });
  const [initialValues, setInitialValues] = useState({
    name: "",
    path: "",
    path_code: "" as string | null,
    type_id: 2,
  });

  useEffect(() => {
    if (update) {
      const r = resources.find((r) => r.id === Number(params.id));
      if (r)
        setInitialValues({
          name: r.name,
          path: r.path,
          type_id: r.type_id,
          path_code: r.code as string | null,
        });
      return;
    }
    const type = types.find((t) => t.type === "Image");
    if (type) setInitialValues({ ...initialValues, type_id: type.id });
  }, [types, params, resources]);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
    path: Yup.string().required(),
  });

  const onSuccess = (resetForm: Function) => {
    dispatch(getResources());
    setSnack({ ...snack, open: true });
    resetForm();
  };
  const onSubmit = (
    values: IValues,
    { setSubmitting, resetForm }: FormikHelpers<IValues>
  ) => {
    setSubmitting(true);
    setError("");
    if (update) {
      api
        .updateResource(
          { ...values, code: values.path_code },
          Number(params.id)
        )
        .then(() => {
          onSuccess(resetForm);
        })
        .catch((e) => {
          setError("Failed To Update Resource. Please Try Again");
        })
        .finally(() => {
          setSubmitting(false);
        });
    }
    api
      .addResource({ ...values, code: values.path_code })
      .then(() => {
        onSuccess(resetForm);
      })
      .catch((e) => {
        setError("Failed To Add Resource. Please Try Again");
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <FormContainer className="mt-4">
      <div className="mb-4">{update ? "Update Resource" : "New Image"}</div>

      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <form>
            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              open={snack.open}
              onClose={() => setSnack({ ...snack, open: false })}
              message={snack.message}
            />
            <Alert message={error} />
            <CloudinaryField
              name="path"
              values={values}
              setFieldValue={setFieldValue}
            />

            <Field
              value={values.name}
              name="name"
              label="Name"
              type="text"
              onChange={handleChange}
              touched={touched.name}
              error={errors.name}
              placeholder="Enter Name"
            ></Field>

            <input type="hidden" name="type_id" value={values.type_id} />
            <FormFooter>
              <SubmitButton
                isSubmitting={isSubmitting}
                handleSubmit={handleSubmit}
              />
            </FormFooter>
          </form>
        )}
      </Formik>
    </FormContainer>
  );
}

type Props = {
  update?: boolean;
};
type IValues = {
  name: string;
  path: string;
  path_code: string | null;
  type_id: number;
};

export default ImageForm;
