import React from "react";
// @ts-ignore
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsers } from "@fortawesome/free-solid-svg-icons";
import { theme } from "../../../../theme";
import { useHistory } from "react-router-dom";

function Card({ name, count, icon, url }: Props) {
  const history = useHistory();
  return (
    <div
      className="flex bg-white shadow cursor-pointer p-5 content-center text-center"
      onClick={() => history.push(url)}
    >
      <Icon>
        <Name>{name}</Name>
        <FontAwesomeIcon icon={icon}></FontAwesomeIcon>
      </Icon>
      <div className="pl-4 py-3">
        <Count>{count}</Count>
      </div>
    </div>
  );
}

type Props = {
  name: string;
  count: number;
  icon: any;
  url: string;
};
export default Card;

const Icon = styled.div`
  font-size: 4rem;
  color: ${theme.primaryColor.default};
`;

const Count = styled.div`
  color: gray;
  font-size: 5rem;
`;

const Name = styled.div`
  color: ${theme.primaryColor.default};
  font-size: 1.5rem;
`;
