import actions from "./actions";
import API from "../../api";

export const resetError = (action: string) => {
  return {
    type: actions.resetError,
    payload: action,
  };
};

export const getProjects = () => {
  return {
    type: actions.getProjects,
    payload: API.getProjects(),
  };
};

export const getArticles = () => {
  return {
    type: actions.getArticles,
    payload: API.getArticles(),
  };
};

export const getResources = () => {
  return {
    type: actions.getResources,
    payload: API.getResources(),
  };
};

export const getUsers = () => {
  return {
    type: actions.getUsers,
    payload: API.getUsers(),
  };
};

export const getMembers = () => {
  return {
    type: actions.getMembers,
    payload: API.getMembers(),
  };
};

export const getStaff = () => {
  return {
    type: actions.getStaff,
    payload: API.getStaff(),
  };
};

export const getDonors = () => {
  return {
    type: actions.getDonors,
    payload: API.getDonors(),
  };
};

export const getDetails = () => ({
  type: actions.getDetails,
  payload: API.fetchDetails(),
});
