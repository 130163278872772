import React from "react";
import PageContainer from "../../../components/PageContainer";
import { Grid } from "@material-ui/core";
import Card from "./components/Card";
import {
  faNewspaper,
  faProjectDiagram,
  faUsers,
  faDatabase,
} from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import { IState } from "../../../Services/types";
import SliderList from "./components/Slider/List";

function Dashboard() {
  const data = useSelector((state: IState) => state.data);
  return (
    <PageContainer>
      <Grid container justify="center" spacing={4}>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <Card
            name="Articles"
            count={data.articles.length}
            icon={faNewspaper}
            url="/articles"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <Card
            name="Projects"
            count={data.projects.length}
            icon={faProjectDiagram}
            url="/projects"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <Card
            name="Staff"
            count={data.staff.length}
            icon={faUsers}
            url="/staff"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <Card
            name="Partners"
            count={data.donors.length}
            icon={faDatabase}
            url="/partners"
          />
        </Grid>
      </Grid>
      <SliderList />
    </PageContainer>
  );
}

export default Dashboard;
