import React from "react";
import Card from "../../../../components/Card";
import { IDonor } from "../../../../Services/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import swal from "sweetalert2";
import api from "../../../../Services/api";
import { useDispatch } from "react-redux";
import { getDonors } from "../../../../Services/redux/actions/data";
import { ellipses } from "../../../../Services/helpers";

function PartnerCard({ partner }: Props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const onDelete = (id: number) => {
    swal
      .fire({
        type: "warning",
        title: "Are you sure?",
        text: "You will not be able to recover this record!",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, keep it",
      })
      .then((result: any) => {
        if (result.value) {
          api
            .deleteResource("partners", id)
            .then(() => {
              swal.fire("Deleted!", "Partner deleted.", "success");
              dispatch(getDonors());
            })
            .catch(() => {
              swal.fire(
                "Failed to delete",
                "Failed to delete partner.",
                "error"
              );
            });

          return;
        }
      });
  };
  return (
    <Card image={partner.picture.path} height={20} shadow>
      <div className="text-sm p-4">
        <strong>{partner.name}</strong>
        <div className="text-orange-600">
          <a target="_blank" href={partner.website}>
            {ellipses(partner.website, 30)}
          </a>
        </div>
        <div className="flex my-6 justify-center items-center">
          <FontAwesomeIcon
            onClick={() => history.push(`/partners/edit/${partner.id}`)}
            icon={faEdit}
            className="text-orange-400 cursor-pointer"
          />
          <FontAwesomeIcon
            onClick={() => onDelete(partner.id)}
            icon={faTrash}
            className="text-red-400 ml-4 cursor-pointer"
          />
        </div>
      </div>
    </Card>
  );
}

type Props = {
  partner: IDonor;
};
export default PartnerCard;
