import React from "react";
// @ts-ignore
import styled from "styled-components";
import { LazyLoadComponent } from "react-lazy-load-image-component";

function Card({
  className,
  image,
  children,
  height,
  orientation = "vertical",
  shadow,
  inverse,
}: Props) {
  const getOrientation = orientation || "vertical";
  const sectionHeight =
    getOrientation === "vertical"
      ? height
        ? height / 2
        : 20 / 2
      : height || 20;

  const display = getOrientation === "vertical" ? "block" : "flex";

  const shadowClass = shadow && "shadow-md";

  return (
    <Wrapper className={`${display} ${shadowClass} ${className}`}>
      {image && !inverse && (
        <ImageContainer
          height={sectionHeight}
          className="flex-1"
          image={image}
        ></ImageContainer>
      )}
      <Description className="flex-1" height={sectionHeight}>
        {children}
      </Description>
      {image && inverse && (
        <ImageContainer
          height={sectionHeight}
          className="flex-1"
          image={image}
        ></ImageContainer>
      )}
    </Wrapper>
  );
}

type Props = {
  image?: string;
  children?: any;
  height?: number;
  orientation?: "vertical" | "horizontal";
  shadow?: boolean;
  className?: any;
  inverse?: boolean;
};
export default Card;

function ImageContainer({
  height,
  className,
  image,
}: {
  className: string;
  height: number;
  image: any;
}) {
  return (
    <LazyLoadComponent>
      <ImageWrapper height={height} className={className}>
        <Image image={image}></Image>
      </ImageWrapper>
    </LazyLoadComponent>
  );
}

const Image = styled("div")<any>`
  height: 100%;
  width: 100%;
  background-image: url(${(props: any) => props.image});
  background-size: cover;
  background-position: center;

  transition: -webkit-transform 0.9s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 0.9s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 0.9s cubic-bezier(0.215, 0.61, 0.355, 1),
    -webkit-transform 0.9s cubic-bezier(0.215, 0.61, 0.355, 1);
  will-change: transform;
`;

const Wrapper = styled("div")`
  &:hover ${Image} {
    transform: scale(1.1);
  }
`;

const ImageWrapper = styled("div")`
  height: ${(props: any) => `${props.height}rem`};
  overflow: hidden;
`;

const Description = styled("div")<any>`
  height: ${(props: any) => `${props.height}rem`};
`;
