import React, { useEffect } from "react";
import LoginForm from "./LoginForm";
// @ts-ignore
import styled from "styled-components";
import { theme } from "../../theme";
import logo from "../../csona.png";
import { useHistory, useParams } from "react-router-dom";
import jwt from "jwt-decode";
import { useDispatch, useSelector } from "react-redux";
import { IState } from "../../Services/types";
import { setAuth } from "../../Services/redux/actions/auth";
import Api from "../../Services/api";
import { tknExpired } from "../../Services/helpers";

function Login() {
  const history = useHistory();
  const checkLogin = () => {
    if (tknExpired()) return false;
    return true;
  };

  useEffect(() => {
    if (checkLogin()) history.push("/dashboard");
  }, [checkLogin]);

  return (
    <Wrapper className="flex justify-center pt-20">
      <div>
        <Logo>
          <img src={logo}></img>
        </Logo>
        <LoginForm />
      </div>
    </Wrapper>
  );
}

export default Login;

const Wrapper = styled.div`
  background: ${theme.primaryColor.default};
  height: 100vh;
  width: 100vw;
`;

const Logo = styled.div`
  position: relative;
  z-index: 99;
  border-radius: 50%;
  margin: 10px auto;
  text-align: center;
  width: 7rem;
  height: 7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  img {
    width: 90%;
    margin: 0rem auto;
  }
`;
