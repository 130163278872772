import React, { useEffect } from "react";
import "./App.css";
import "./assets/css/main.css";
import "./assets/lib/animate.min.css";
import { Switch, Route, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  getProjects,
  getArticles,
  getResources,
  getStaff,
  getDonors,
  getMembers,
  getDetails,
} from "./Services/redux/actions/data";
import ArticlesList from "./Pages/Articles/List";
import Dashboard from "./Pages/Dashboard/List";
import PartnersList from "./Pages/Partners/List";
import ProjectsList from "./Pages/Projects/List";
import ResourcesList from "./Pages/Resources/List";
import StaffList from "./Pages/Staff/List";
import UsersList from "./Pages/Users/List";
import Login from "./Pages/Login";
import CreateArticle from "./Pages/Articles/Create";
import { getMeta } from "./Services/redux/actions/meta";
import CreateProject from "./Pages/Projects/Create";
import CreateStaff from "./Pages/Staff/Create";
import CreatePartner from "./Pages/Partners/Create";
import CreateResource from "./Pages/Resources/Create";
import CreateUser from "./Pages/Users/Create";
import ViewArticle from "./Pages/Articles/View";
import ViewProject from "./Pages/Projects/View";
import UpdateArticle from "./Pages/Articles/Update";
import UpdateProject from "./Pages/Projects/Update";
import UpdateStaff from "./Pages/Staff/Update";
import UpdatePartner from "./Pages/Partners/Update";
import UpdateResource from "./Pages/Resources/Update";
import { tknExpired } from "./Services/helpers";
import Profile from "./Pages/Users/Profile";
import ChangePassword from "./Pages/Users/Profile/ChangePassword";
import ForgotPassword from "./Pages/Login/PasswordReset/ForgotPassword";
import PasswordReset from "./Pages/Login/PasswordReset/PasswordReset";
import MembersList from "./Pages/Members/List";

import UpdateMember from "./Pages/Members/Update";
import CreateMember from "./Pages/Members/Create";
import CreateSlider from "./Pages/Dashboard/List/components/Slider/Create";
import UpdateSlider from "./Pages/Dashboard/List/components/Slider/Update";

function App() {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    setTimeout(
      () => document.getElementById("preloader")?.setAttribute("class", "none"),
      2000
    );
    dispatch(getDetails());
    dispatch(getMeta("article_types"));
    dispatch(getMeta("project_statuses"));
    dispatch(getMeta("resource_types"));
    dispatch(getMeta("staff_types"));
    dispatch(getMeta("districts"));
    dispatch(getProjects());
    dispatch(getProjects());
    dispatch(getProjects());
    dispatch(getArticles());
    dispatch(getResources());
    dispatch(getStaff());
    dispatch(getMembers());
    dispatch(getDonors());
  }, [dispatch]);

  const ScrollToTop = () => {
    if (history.location.hash.length === 0) window.scrollTo(0, 0);
    return null;
  };
  return (
    <div>
      <div>
        <Route component={ScrollToTop} />
        <Switch>
          <Route exact path="/">
            <Login />
          </Route>
          <Route exact path="/forgot_password">
            <ForgotPassword />
          </Route>
          <Route exact path="/reset_password/:token">
            <PasswordReset />
          </Route>

          <Route exact path="/articles/add">
            <CreateArticle />
          </Route>
          <Route exact path="/articles">
            <ArticlesList />
          </Route>
          <Route exact path="/articles/edit/:id">
            <UpdateArticle />
          </Route>
          <Route exact path="/articles/:id">
            <ViewArticle />
          </Route>

          <Route exact path="/projects">
            <ProjectsList />
          </Route>
          <Route exact path="/projects/add">
            <CreateProject />
          </Route>
          <Route exact path="/projects/edit/:id">
            <UpdateProject />
          </Route>
          <Route exact path="/projects/:id">
            <ViewProject />
          </Route>

          <Route exact path="/staff">
            <StaffList />
          </Route>
          <Route exact path="/staff/edit/:id">
            <UpdateStaff />
          </Route>
          <Route exact path="/staff/add">
            <CreateStaff />
          </Route>

          <Route exact path="/members">
            <MembersList />
          </Route>
          <Route exact path="/members/edit/:id">
            <UpdateMember />
          </Route>
          <Route exact path="/members/add">
            <CreateMember />
          </Route>

          <Route exact path="/dashboard">
            <Dashboard />
          </Route>
          <Route exact path="/slider/edit/:id">
            <UpdateSlider />
          </Route>
          <Route exact path="/slider/add">
            <CreateSlider />
          </Route>

          <Route exact path="/partners">
            <PartnersList />
          </Route>
          <Route exact path="/partners/edit/:id">
            <UpdatePartner />
          </Route>
          <Route exact path="/partners/add">
            <CreatePartner />
          </Route>

          <Route exact path="/resources">
            <ResourcesList />
          </Route>
          <Route exact path="/resources/edit/:id/:type">
            <UpdateResource />
          </Route>
          <Route exact path="/resources/add">
            <CreateResource />
          </Route>

          <Route exact path="/users">
            <UsersList />
          </Route>
          <Route exact path="/users/add">
            <CreateUser />
          </Route>
          <Route exact path="/users/profile">
            <Profile />
          </Route>
          <Route exact path="/users/change_password">
            <ChangePassword />
          </Route>
        </Switch>
      </div>
    </div>
  );
}

export default App;
