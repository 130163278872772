import { combineReducers } from "redux";

import status from "./status";
import errors from "./errors";
import data from "./data";
import auth from "./auth";
import meta from "./meta";

const rootReducer = combineReducers({
  auth,
  loading: status,
  meta,
  errors,
  data,
});

export default rootReducer;
