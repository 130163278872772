import React, { useState } from "react";
import { Grid } from "@material-ui/core";
// @ts-ignore
import styled from "styled-components";
import { theme } from "../../theme";
import { Formik, Field, ErrorMessage, FormikHelpers } from "formik";
import * as Yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import Api from "../../Services/api";
import jwt from "jwt-decode";
import { useDispatch } from "react-redux";
import { setAuth } from "../../Services/redux/actions/auth";
import { useHistory } from "react-router-dom";

function LoginForm() {
  const [message, setMessage] = useState({ type: "success", message: "" });
  const dispatch = useDispatch();
  const history = useHistory();
  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Required"),
    password: Yup.string().required("Required"),
  });

  const onSubmit = (
    values: {
      email: string;
      password: string;
    },
    {
      setSubmitting,
      setFieldValue,
    }: FormikHelpers<{
      email: string;
      password: string;
    }>
  ) => {
    setSubmitting(true);
    setMessage({
      type: "none",
      message: "",
    });
    Api.login(values)
      .then((response) => {
        const tkn = response.data.token;
        const decoded = jwt(tkn);

        // @ts-ignore
        Api.getUser(decoded.sub, tkn)
          .then((res) => {
            sessionStorage.setItem("token", tkn);
            const user = res.data;
            dispatch(setAuth({ authenticated: true, user }));

            history.location.search === "?tkn"
              ? history.goBack()
              : history.push("/dashboard");
          })
          .catch(() => {
            setMessage({
              type: "error",
              message: "Credencials expired",
            });
          });
      })
      .catch(() => {
        setFieldValue("password", "");
        setMessage({
          type: "error",
          message: "Invalid Email or Password",
        });
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <Container className="text-base shadow">
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form className="m-4">
            <label>Email</label>
            <Field
              className={touched.email && errors.email ? "error" : ""}
              type="text"
              id="email"
              name="email"
              onChange={handleChange}
              value={values.email}
              placeholder="Enter Your Email"
              required
            />
            <div className="text-red-400 text-sm">
              <ErrorMessage name="email" />
            </div>
            <label>Password</label>
            <Field
              className={touched.password && errors.password ? "error" : ""}
              type="password"
              id="password"
              name="password"
              onChange={handleChange}
              value={values.password}
              placeholder="Password"
              required
            />
            <div className="text-red-400 text-sm">
              <ErrorMessage name="password" />
            </div>
            {message.message.length > 0 && (
              <div className="text-base text-center mt-2">
                {message.type === "success" ? (
                  <div className="text-green-400">
                    <FontAwesomeIcon icon={faCheckCircle} className="mr-2" />
                    {message.message}
                  </div>
                ) : (
                  <div className="text-red-400">
                    <FontAwesomeIcon icon={faTimesCircle} className="mr-2" />
                    {message.message}
                  </div>
                )}
              </div>
            )}
            <Field
              disabled={isSubmitting}
              onClick={handleSubmit}
              type="submit"
              value={isSubmitting ? "Requesting..." : "Login"}
              className="bg-orange-700 hover:bg-orange-800 text-white font-bold py-4 px-4 my-4 border border-orange-700 rounded cursor-pointer"
            />
          </Form>
        )}
      </Formik>
      <div className="underline text-right text-sm">
        <span
          className="cursor-pointer"
          onClick={() => history.push("/forgot_password")}
        >
          Forgot Password
        </span>
      </div>
    </Container>
  );
}

export default LoginForm;

const Container = styled.div`
  margin-top: -4rem;
  width: 400px;
  padding: 1rem;
  background: white;
  /* border-top: 2px solid ${theme.primaryColor.default}; */
`;
const Form = styled.form`
  input {
    &.error {
      border: 1px solid red;
    }
    display: block;
    width: 100%;
    border: 1px solid #ededed;
    padding: 0.2rem;
  }
  label {
    display: block;
    margin-top: 1rem;
    color: ${theme.primaryColor.default};
  }
  textarea {
    &.error {
      border: 1px solid red;
    }
    display: block;
    width: 100%;
    border: 1px solid #ededed;
    padding: 0.3rem;
  }
`;
