import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import swal from "sweetalert2";

import { useDispatch, useSelector } from "react-redux";
import api from "../../../../../../../Services/api";
import { getDetails } from "../../../../../../../Services/redux/actions/data";

import { IState } from "../../../../../../../Services/types";
import Card from "../../../../../../../components/Card";

function SliderCard({ slider }: Props) {
  const details = useSelector((state: IState) => state.data.details);
  const history = useHistory();
  const dispatch = useDispatch();
  const onDelete = (id: string) => {
    swal
      .fire({
        type: "warning",
        title: "Are you sure?",
        text: "You will not be able to recover this record!",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, keep it",
      })
      .then((result: any) => {
        if (result.value) {
          const data = {
            details: {
              ...details,
              slider: details.slider?.filter((q) => q.id !== id) || [],
            },
          };
          api
            .updateDetails(data)
            .then(() => {
              swal.fire("Deleted!", "Slider deleted.", "success");
              dispatch(getDetails());
            })
            .catch(() => {
              swal.fire(
                "Failed to delete",
                "Failed to delete slider.",
                "error"
              );
            });

          return;
        }
      });
  };
  return (
    <Card image={slider.src} height={20} shadow>
      <div className="text-sm p-4">
        <strong>{slider.caption}</strong>
        <div className="flex my-6 justify-center items-center">
          <FontAwesomeIcon
            onClick={() => history.push(`/slider/edit/${slider.id}`)}
            icon={faEdit}
            className="text-orange-400 cursor-pointer"
          />
          <FontAwesomeIcon
            onClick={() => onDelete(slider.id)}
            icon={faTrash}
            className="text-red-400 ml-4 cursor-pointer"
          />
        </div>
      </div>
    </Card>
  );
}

type Props = {
  slider: {
    id: string;
    src: string;
    caption: string;
  };
};
export default SliderCard;
