import React, { useState, useEffect } from "react";
import { Formik, FormikHelpers } from "formik";
import * as Yup from "yup";

import { useDispatch, useSelector } from "react-redux";
import { Snackbar } from "@material-ui/core";

import { useParams } from "react-router-dom";
import { IState } from "../../../../../../../Services/types";
import { getDetails } from "../../../../../../../Services/redux/actions/data";
import api from "../../../../../../../Services/api";
import {
  CloudinaryField,
  Field,
  FormContainer,
  FormFooter,
  SubmitButton,
} from "../../../../../../../components/Form";
import Alert from "../../../../../../../components/Alert";
import { v4 as uuidv4 } from "uuid";

function SliderForm({ update }: Props) {
  const params = useParams();

  const dispatch = useDispatch();

  const details = useSelector((state: IState) => state.data.details);

  const [error, setError] = useState("");

  const [snack, setSnack] = useState({ open: false, message: "Slider Saved" });

  const [initialValues, setInitialValues] = useState({
    src: "",
    caption: "",
  });

  useEffect(() => {
    if (update && details.slider) {
      // @ts-ignore
      const p = details.slider.find((p) => p.id === params.id);
      if (p)
        setInitialValues({
          src: p.src,
          caption: p.caption,
        });
    }
  }, [params, details]);

  const validationSchema = Yup.object().shape({
    src: Yup.string().required("Required"),
    caption: Yup.string().required(),
  });

  const onSuccess = (resetForm: Function) => {
    dispatch(getDetails());
    setSnack({ ...snack, open: true });
    resetForm();
  };
  const onSubmit = (
    values: IValues,
    { setSubmitting, resetForm }: FormikHelpers<IValues>
  ) => {
    setSubmitting(true);
    setError("");
    const data = {
      details: {
        ...details,
        slider: details?.slider
          ? [
              ...details?.slider,
              {
                ...values,
                id: uuidv4(),
              },
            ]
          : [
              {
                ...values,
                id: uuidv4(),
              },
            ],
      },
    };
    api
      .updateDetails(data)
      .then(() => {
        onSuccess(resetForm);
      })
      .catch((e) => {
        setError("Failed To Add Slider. Please Try Again");
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <FormContainer className="shadow p-5">
      <div className="mb-4">{update ? "Update Slider" : "New Slider"}</div>

      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <form>
            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              open={snack.open}
              onClose={() => setSnack({ ...snack, open: false })}
              message={snack.message}
            />
            <Alert message={error} />
            <CloudinaryField
              width="20%"
              name="src"
              values={values}
              setFieldValue={setFieldValue}
            />

            <Field
              value={values.caption}
              name="caption"
              label="Caption"
              type="text"
              onChange={handleChange}
              touched={touched.caption}
              error={errors.caption}
              placeholder="Enter Caption"
            ></Field>

            <FormFooter>
              <SubmitButton
                isSubmitting={isSubmitting}
                handleSubmit={handleSubmit}
              />
            </FormFooter>
          </form>
        )}
      </Formik>
    </FormContainer>
  );
}

type Props = {
  update?: boolean;
};
type IValues = {
  src: string;
  caption: string;
};

export default SliderForm;
