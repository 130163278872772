import React from "react";
import PageContainer from "../../../components/PageContainer";
import ArticleForm from "../Create/components/Form";

function UpdateArticle() {
  return (
    <PageContainer addBtn>
      <ArticleForm update />
    </PageContainer>
  );
}

export default UpdateArticle;
