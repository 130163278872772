import React from "react";
import PageContainer from "../../../components/PageContainer";
import StaffForm from "../Create/components/Form";

function UpdateStaff() {
  return (
    <PageContainer addBtn>
      <StaffForm update />
    </PageContainer>
  );
}

export default UpdateStaff;
