import React, { useState, useEffect } from "react";
import { Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import {
  FormContainer,
  Field,
  FormFooter,
  SubmitButton,
} from "../../../../components/Form";
import { useSelector } from "react-redux";
import { Snackbar } from "@material-ui/core";
import api from "../../../../Services/api";
import Alert from "../../../../components/Alert";
import { useHistory } from "react-router-dom";
import { IState } from "../../../../Services/types";

function ChangePasswordForm() {
  const history = useHistory();
  const user = useSelector((state: IState) => state.auth.user);

  const [error, setError] = useState("");
  const [snack, setSnack] = useState({ open: false, message: "User Added" });

  const [initialValues, setInitialValues] = useState({
    old_password: "",
    email: "",
    password: "",
    confirm_password: "",
  });

  useEffect(() => {
    setInitialValues({ ...initialValues, email: user.email });
  }, [user]);

  const validationSchema = Yup.object().shape({
    old_password: Yup.string().required("Required"),
    email: Yup.string().email().required(),
    password: Yup.string().required().min(5),
    confirm_password: Yup.string()
      .required()
      .oneOf([Yup.ref("password")], "Passwords do not match"),
  });

  const onSubmit = (
    values: IValues,
    { setSubmitting, resetForm }: FormikHelpers<IValues>
  ) => {
    setSubmitting(true);
    setError("");
    api
      .changePassword(values)
      .then(() => {
        sessionStorage.clear();
        history.push("/");
      })
      .catch((e: any) => {
        setError("Failed To Change Password. Please Try Again");
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <FormContainer className="shadow p-5">
      <div className="mb-4">Change Password</div>

      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <form>
            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              open={snack.open}
              onClose={() => setSnack({ ...snack, open: false })}
              message={snack.message}
            />
            <Alert message={error} />

            <input value={values.email} name="email" type="hidden"></input>

            <Field
              value={values.old_password}
              name="old_password"
              label="Current Password"
              type="password"
              onChange={handleChange}
              touched={touched.old_password}
              error={errors.old_password}
              placeholder="Current Password"
            ></Field>

            <Field
              value={values.password}
              name="password"
              label="New Password"
              type="password"
              onChange={handleChange}
              touched={touched.password}
              error={errors.password}
              placeholder="Password"
            ></Field>

            <Field
              value={values.confirm_password}
              name="confirm_password"
              label="Confirm Password"
              type="password"
              onChange={handleChange}
              touched={touched.confirm_password}
              error={errors.confirm_password}
              placeholder="Confirm Password"
            ></Field>
            <FormFooter>
              <SubmitButton
                isSubmitting={isSubmitting}
                handleSubmit={handleSubmit}
              />
            </FormFooter>
          </form>
        )}
      </Formik>
    </FormContainer>
  );
}

type IValues = {
  email: string;
  old_password: string;
  password: string;
  confirm_password: string;
};

export default ChangePasswordForm;
