import React, { useState, useEffect } from "react";
import PageContainer from "../../../components/PageContainer";
import {
  FieldContainer,
  RadioGroup,
  FormContainer,
} from "../../../components/Form";
import ImageForm from "./components/ImageForm";
import DocForm from "./components/DocForm";
import { useParams } from "react-router-dom";

function CreateResource({ update }: Props) {
  const params: { type: string } = useParams();

  const resourceTypes = [
    { name: "Image", id: 2 },
    { name: "Doc", id: 1 },
    { name: "Membership", id: 5 },
    { name: "Members List", id: 6 },
  ];

  const [activeType, setactiveType] = useState(2);

  useEffect(() => {
    if (update) {
      const type = resourceTypes.find((r) => r.name === params.type);
      if (type) setactiveType(type.id);
    }
  }, [params]);

  const onTypeChange = (field: string, value: any) => {
    setactiveType(value);
  };
  return (
    <PageContainer>
      <FormContainer className="shadow p-5">
        {!update && (
          <RadioGroup
            label="Resource Type"
            values={{ name: activeType }}
            options={resourceTypes}
            name="name"
            displayField="name"
            setFieldValue={onTypeChange}
          />
        )}

        {activeType === 2 && <ImageForm update={update} />}
        {(activeType === 1 || activeType === 5 || activeType === 6) && (
          <DocForm update={update} restype={activeType} />
        )}
      </FormContainer>
    </PageContainer>
  );
}

type Props = {
  update?: boolean;
};
export default CreateResource;
