import React, { useState, useEffect } from "react";
import { Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import {
  FormContainer,
  Field,
  FormFooter,
  SubmitButton,
  RadioGroup,
  SelectField,
} from "../../../../components/Form";
import { useSelector, useDispatch } from "react-redux";
import { IState } from "../../../../Services/types";
import { Snackbar } from "@material-ui/core";
import api from "../../../../Services/api";
import Alert from "../../../../components/Alert";
import { getMembers } from "../../../../Services/redux/actions/data";
import { useParams } from "react-router-dom";

function MemberForm({ update }: Props) {
  const params = useParams();

  const districts = useSelector((state: IState) => state.meta.districts);

  const members = useSelector((state: IState) => state.data.members);

  const dispatch = useDispatch();

  const [error, setError] = useState("");

  const [snack, setSnack] = useState({ open: false, message: "Member Saved" });

  const [initialValues, setinitialValues] = useState({
    name: "",
    about: "",
    website: "",
    district_id: 0 as number,
  });

  useEffect(() => {
    if (update) {
      // @ts-ignore
      const s = members.find((s) => s.id === Number(params.id));
      if (s)
        setinitialValues({
          name: s.name,
          about: s.about,
          website: s.website,
          district_id: s.district_id as number,
        });
      return;
    }
    setinitialValues({
      ...initialValues,
      district_id: districts.length > 0 ? (districts[0].id as number) : 0,
    });
  }, [districts, params, members]);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
    district_id: Yup.number().required(),
  });

  const onSuccess = (resetForm: Function) => {
    dispatch(getMembers());
    setSnack({ ...snack, open: true });
    resetForm();
  };

  const onSubmit = (
    values: IValues,
    { setSubmitting, resetForm }: FormikHelpers<IValues>
  ) => {
    setSubmitting(true);
    setError("");
    if (update) {
      api

        .updateMember(
          values,
          // @ts-ignore
          params.id
        )
        .then(() => {
          onSuccess(resetForm);
        })
        .catch((e) => {
          setError("Failed To Update Member. Please Try Again");
        })
        .finally(() => {
          setSubmitting(false);
        });
      return;
    }
    api
      .addMember(values)
      .then(() => {
        onSuccess(resetForm);
      })
      .catch((e) => {
        setError("Failed To Add Member. Please Try Again");
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <FormContainer className="shadow p-5">
      <div className="mb-4">{update ? "Update Member" : "New Member"}</div>

      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <form>
            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              open={snack.open}
              onClose={() => setSnack({ ...snack, open: false })}
              message={snack.message}
            />
            <Alert message={error} />

            <Field
              value={values.name}
              name="name"
              label="Org Name"
              type="text"
              onChange={handleChange}
              touched={touched.name}
              error={errors.name}
              placeholder="Enter Organisation Name"
            ></Field>

            <Field
              value={values.about}
              name="about"
              label="About Organisation"
              type="text"
              onChange={handleChange}
              touched={touched.about}
              error={errors.about}
              placeholder="About Org."
            ></Field>
            <Field
              value={values.website}
              name="website"
              label="Website"
              type="text"
              onChange={handleChange}
              touched={touched.website}
              error={errors.website}
              placeholder="https://example.com"
            ></Field>

            <SelectField
              placeholder="Select District"
              name="district_id"
              label="District"
              value={values.district_id}
              onChange={handleChange}
              touched={touched.district_id}
              error={errors.district_id}
              required
              values={{
                items: districts,
                value: "id",
                label: "name",
              }}
            ></SelectField>

            <FormFooter>
              <SubmitButton
                isSubmitting={isSubmitting}
                handleSubmit={handleSubmit}
              />
            </FormFooter>
          </form>
        )}
      </Formik>
    </FormContainer>
  );
}

type Props = {
  update?: boolean;
};
type IValues = {
  name: string;
  about: string;
  website: string;
  district_id: number;
};

export default MemberForm;
