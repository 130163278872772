import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";

function CloudinaryWidget({ onUpload }: Props) {
  const [widget, setWidget] = useState(null);
  const [picture, setPicture] = useState("");
  useEffect(() => {
    // @ts-ignore
    const widget = window.cloudinary.createUploadWidget(
      {
        cloudName: process.env.REACT_APP_CLOUDINARY_NAME,
        uploadPreset: process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET,
        multiple: false,
        thumbnailTransformation: "q_55",
      },
      (error: any, result: any) => {
        if (result && result.event === "success") {
          const assetUrl = result.info.thumbnail_url;
          const publicId = result.info.public_id;
          onUpload(assetUrl, publicId);
          setPicture(assetUrl);
        }
      }
    );
    setWidget(widget);
  }, [picture]);

  return (
    <div className="my-4">
      <span
        onClick={() => {
          // @ts-ignore
          widget && widget.open();
        }}
        className="cursor-pointer bg-orange-500 hover:bg-orange-700 text-white font-bold py-2 px-4 rounded-full"
      >
        <FontAwesomeIcon className="mr-2" icon={faUpload} />
        Upload Image
      </span>
    </div>
  );
}

type Props = {
  onUpload: Function;
};
export default CloudinaryWidget;

// access_mode: "public"
// asset_id: "1e513e27e8c3e8e6432137ac83dec942"
// batchId: "uw-batch2"
// bytes: 7174
// created_at: "2020-06-01T14:52:15Z"
// etag: "8ab2b00d92e8a0152d37cc34b0a32c0f"
// format: "jpg"
// height: 183
// id: "uw-file3"
// original_filename: "images2"
// path: "v1591023135/hvszvyz76jlenig2d5b2.jpg"
// placeholder: false
// public_id: "hvszvyz76jlenig2d5b2"
// resource_type: "image"
// secure_url: "https://res.cloudinary.com/csona/image/upload/v1591023135/hvszvyz76jlenig2d5b2.jpg"
// signature: "2b904ff00d5d7d751b1e21625909241d39233c04"
// tags: []
// thumbnail_url: "https://res.cloudinary.com/csona/image/upload/c_limit,h_60,w_90/v1591023135/hvszvyz76jlenig2d5b2.jpg"
// type: "upload"
// url: "http://res.cloudinary.com/csona/image/upload/v1591023135/hvszvyz76jlenig2d5b2.jpg"
// version: 1591023135
// version_id: "60be9cda686913ff5c8a8c9ec986911b"
// width: 276
