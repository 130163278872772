import React, { useState } from "react";
import { Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import {
  FormContainer,
  Field,
  FormFooter,
  SubmitButton,
} from "../../../../components/Form";
import { useDispatch } from "react-redux";
import { Snackbar } from "@material-ui/core";
import api from "../../../../Services/api";
import Alert from "../../../../components/Alert";
import { getUsers } from "../../../../Services/redux/actions/data";

function UsersForm() {
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const [snack, setSnack] = useState({ open: false, message: "User Added" });
  const initialValues = {
    name: "",
    email: "",
    password: "",
    confirm_password: "",
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
    email: Yup.string().email().required(),
    password: Yup.string().required().min(5),
    confirm_password: Yup.string()
      .required()
      .oneOf([Yup.ref("password")], "Passwords do not match"),
  });

  const onSubmit = (
    values: IValues,
    { setSubmitting, resetForm }: FormikHelpers<IValues>
  ) => {
    setSubmitting(true);
    setError("");
    api
      .addUser(values)
      .then(() => {
        dispatch(getUsers());
        setSnack({ ...snack, open: true });
        resetForm();
      })
      .catch((e) => {
        setError("Failed To Add User. Please Try Again");
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <FormContainer className="shadow p-5">
      <div className="mb-4">New User</div>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <form>
            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              open={snack.open}
              onClose={() => setSnack({ ...snack, open: false })}
              message={snack.message}
            />
            <Alert message={error} />

            <Field
              value={values.name}
              name="name"
              label="Name"
              type="text"
              onChange={handleChange}
              touched={touched.name}
              error={errors.name}
              placeholder="Enter User Name"
            ></Field>

            <Field
              value={values.email}
              name="email"
              label="Email"
              type="email"
              onChange={handleChange}
              touched={touched.email}
              error={errors.email}
              placeholder="Email"
            ></Field>

            <Field
              value={values.password}
              name="password"
              label="Password"
              type="password"
              onChange={handleChange}
              touched={touched.password}
              error={errors.password}
              placeholder="Password"
            ></Field>

            <Field
              value={values.confirm_password}
              name="confirm_password"
              label="Confirm Password"
              type="password"
              onChange={handleChange}
              touched={touched.confirm_password}
              error={errors.confirm_password}
              placeholder="Confirm Password"
            ></Field>
            <FormFooter>
              <SubmitButton
                isSubmitting={isSubmitting}
                handleSubmit={handleSubmit}
              />
            </FormFooter>
          </form>
        )}
      </Formik>
    </FormContainer>
  );
}

type IValues = {
  name: string;
  email: string;
  password: string;
  confirm_password: string;
};

export default UsersForm;
