import React from "react";

import { useSelector } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import MaterialTable from "material-table";

import { Link, useHistory } from "react-router-dom";
import swal from "sweetalert2";
import { Grid } from "@material-ui/core";
import SliderCard from "./components/SliderCard";
import { IState } from "../../../../../../Services/types";
import PageContainer, {
  AddBtn,
} from "../../../../../../components/PageContainer";

function SliderList() {
  const details = useSelector((state: IState) => state.data.details);
  return (
    <div className="my-4">
      <h1 className="my-4 mt-6 font-bold">Slider</h1>
      <div className="flex justify-end my-2">
        <Link to={`/slider/add`}>
          <AddBtn className="shadow">
            <FontAwesomeIcon icon={faPlus} />
          </AddBtn>
        </Link>
      </div>
      <Grid container justify="center" spacing={4}>
        {details.slider?.map((pt) => (
          <Grid key={pt.id} item xs={12} sm={12} md={4} lg={4}>
            <SliderCard slider={pt} />
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

export default SliderList;
