import React from "react";
// @ts-ignore
import styled from "styled-components";
import { theme } from "../../theme";
import { Field as FField, ErrorMessage } from "formik";
import {
  Checkbox,
  CircularProgress,
  ListItemText,
  MenuItem,
  Radio,
  Select,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile } from "@fortawesome/free-solid-svg-icons";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import CloudinaryWidget from "../FileUploads/cloudinary";
import { Input } from "@material-ui/core";
import { SelectInputProps } from "@material-ui/core/Select/SelectInput";

export const FormContainer = styled.div`
  border-top: 3px solid ${theme.primaryColor.default};
  background: white;
  margin-bottom: 2rem;
`;

export const FormFooter = styled.div`
  text-align: right;
  padding: 1rem 0rem;
  margin-top: 2rem;
  border-top: 1px solid #ededed;
`;
export const FieldContainer = styled.div`
  input {
    &.error {
      border: 1px solid red;
    }
    display: block;
    width: 100%;
    border: 1px solid #ededed;
    padding: 0.2rem;
  }
  label {
    display: block;
    margin: 1rem 0rem 0.5rem;
    color: ${theme.primaryColor.darker};
  }
  textarea {
    &.error {
      border: 1px solid red;
    }
    display: block;
    width: 100%;
    border: 1px solid #ededed;
    padding: 0.3rem;
  }
`;

export const Field = ({
  type,
  name,
  label,
  value,
  onChange,
  touched,
  error,
  required,
  placeholder,
  disabled,
}: {
  type: any;
  name: string;
  label: string;
  value: any;
  onChange: Function;
  touched: boolean | undefined;
  error?: string;
  required?: boolean;
  placeholder: string;
  disabled?: boolean;
}) => {
  return (
    <FieldContainer>
      <label>{label}</label>
      <FField
        className={touched && error ? "error" : ""}
        type={type}
        name={name}
        onChange={onChange}
        value={value}
        placeholder={placeholder}
        required={required}
        disabled={disabled}
      />
      <div className="text-red-400 text-sm">
        <ErrorMessage name={name} />
      </div>
    </FieldContainer>
  );
};

export const SubmitButton = ({
  isSubmitting,
  handleSubmit,
}: {
  isSubmitting: boolean;
  handleSubmit: Function;
}) => {
  return (
    <span
      onClick={isSubmitting ? () => {} : () => handleSubmit()}
      className={`cursor-pointer bg-${
        isSubmitting ? "gray" : "blue"
      }-500 hover:bg-${
        isSubmitting ? "gray" : "blue"
      }-700 text-white font-bold py-2 px-4`}
    >
      {isSubmitting ? (
        <CircularProgress
          size={18}
          className="mr-2"
          color="inherit"
        ></CircularProgress>
      ) : (
        <FontAwesomeIcon className="mr-2" icon={faFile} />
      )}
      {isSubmitting ? "Saving..." : "Save"}
    </span>
  );
};

export const RadioGroup = ({
  label,
  options,
  name,
  values,
  setFieldValue,
  displayField,
}: {
  label: string;
  setFieldValue: Function;
  values: any;
  options: Array<any>;
  name: string;
  displayField: string;
}) => {
  return (
    <FieldContainer>
      <label>{label}</label>
      {options.map((op) => (
        <span key={op.id}>
          <Radio
            color="default"
            checked={values[`${name}`] === op.id}
            onChange={(v) => setFieldValue(name, Number(v.target.value))}
            value={op.id}
            name={name}
          />
          <span>{op[`${displayField}`]}</span>
        </span>
      ))}
    </FieldContainer>
  );
};

export const TextArea = ({
  label,
  name,
  values,
  setFieldValue,
  editorModules,
}: {
  label: string;
  values: any;
  name: string;
  setFieldValue: Function;
  editorModules: any;
}) => {
  return (
    <FieldContainer>
      <label>{label}</label>
      <div className="text-red-400 text-sm">
        <ErrorMessage name={name} />
      </div>
      <input type="hidden" name={name} value={values[name]}></input>
      <ReactQuill
        value={values[name]}
        onChange={(html: any) => setFieldValue(name, html)}
        modules={editorModules}
        placeholder=""
      />
    </FieldContainer>
  );
};

export const SelectField = ({
  name,
  label,
  value,
  onChange,
  touched,
  error,
  required,
  placeholder,
  disabled,
  values,
  multiple,
}: {
  name: string;
  label: string;
  value: any;
  onChange: SelectInputProps["onChange"];
  touched: boolean | undefined;
  error?: string;
  required?: boolean;
  placeholder: string;
  disabled?: boolean;
  values: { items: Array<any>; value: any; label: any };
  multiple?: boolean;
}) => {
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  return (
    <FieldContainer>
      <label>
        {label}
        {required && " *"}
      </label>
      <Select
        autoWidth
        className={touched && error ? "error w-full" : "w-full"}
        multiple={multiple}
        value={value}
        name={name}
        onChange={onChange}
        input={<Input />}
        renderValue={
          multiple
            ? (selected: Array<any> | unknown) => {
                const items = (selected as string[]).map(
                  (s) =>
                    values.items.find((i) => i[values.value] == s)[values.label]
                );
                return items.length > 0
                  ? (items as string[]).join(",")
                  : `Select ${label}`;
              }
            : (selected) => {
                const item = values.items.find(
                  (i) => i[values.value] == selected
                );
                return item ? item[values.label] : `Select ${label}`;
              }
        }
        MenuProps={MenuProps}
      >
        <MenuItem disabled value={0}>
          <em>{placeholder}</em>
        </MenuItem>
        {values.items.map((item) => (
          <MenuItem key={item[values.value]} value={item[values.value]}>
            <Checkbox
              checked={
                multiple
                  ? value.indexOf(item[values.value]) > -1
                  : item[values.value] == value
              }
            />
            <ListItemText primary={item[values.label]} />
          </MenuItem>
        ))}
      </Select>
      <div className="text-red-400 text-sm">
        <ErrorMessage name={name} />
      </div>
    </FieldContainer>
  );
};

export const CloudinaryField = ({
  name,
  values,
  setFieldValue,
  width,
}: {
  values: any;
  name: string;
  width?: string;

  setFieldValue: Function;
}) => {
  return (
    <>
      <img
        style={{ width: width ?? "50%" }}
        src={
          values[name].length > 0
            ? values[name]
            : "https://res.cloudinary.com/csona/image/upload/v1591092657/placeholder-image10_om6ciy.jpg"
        }
      />

      <input type="hidden" name={name} value={values[name]}></input>
      <input
        type="hidden"
        name={`${name}_code`}
        value={values[`${name}_code`]}
      />
      <CloudinaryWidget
        onUpload={(assetUrl: string, publicId: string) => {
          setFieldValue(name, assetUrl);
          setFieldValue(`${name}_code`, publicId);
        }}
      />
      <div className="text-red-400 text-sm">
        <ErrorMessage name={name} />
      </div>
    </>
  );
};
