import React from "react";
import PageContainer from "../../../components/PageContainer";
import ProjectForm from "../Create/components/Form";

function UpdateProject() {
  return (
    <PageContainer addBtn>
      <ProjectForm update />
    </PageContainer>
  );
}

export default UpdateProject;
