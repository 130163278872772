import React, { useState } from "react";
import { Grid } from "@material-ui/core";
// @ts-ignore
import styled from "styled-components";
import { Formik, Field, ErrorMessage, FormikHelpers } from "formik";
import * as Yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import api from "../../../Services/api";
import { theme } from "../../../theme";
import logo from "../../../csona.png";
import swal from "sweetalert2";

function ForgotPassword() {
  const [message, setMessage] = useState({ type: "success", message: "" });
  const dispatch = useDispatch();
  const history = useHistory();
  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Required"),
  });

  const onSubmit = (
    values: {
      email: string;
    },
    {
      setSubmitting,
    }: FormikHelpers<{
      email: string;
    }>
  ) => {
    setSubmitting(true);
    setMessage({
      type: "none",
      message: "",
    });
    api
      .forgotPassword(values)
      .then(() => {
        swal.fire("Request Sent!", "Password Reset Email Sent.", "success");
        history.push("/");
      })
      .catch(() => {
        setMessage({
          type: "error",
          message: "Failed to send email, invalid email",
        });
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <Wrapper className="flex justify-center pt-20">
      <div>
        <Logo>
          <img src={logo}></img>
        </Logo>
        <Container className="text-base shadow">
          <div className="mt-10 text-yellow-800">
            Enter your email. The password reset link will be sent to your mail
            box
          </div>

          <Formik
            initialValues={{
              email: "",
            }}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleSubmit,
              isSubmitting,
            }) => (
              <Form className="m-4">
                <label>Email</label>
                <Field
                  className={touched.email && errors.email ? "error" : ""}
                  type="text"
                  id="email"
                  name="email"
                  onChange={handleChange}
                  value={values.email}
                  placeholder="Enter Your Email"
                  required
                />
                <div className="text-red-400 text-sm">
                  <ErrorMessage name="email" />
                </div>

                {message.message.length > 0 && (
                  <div className="text-base text-center mt-2">
                    {message.type === "success" ? (
                      <div className="text-green-400">
                        <FontAwesomeIcon
                          icon={faCheckCircle}
                          className="mr-2"
                        />
                        {message.message}
                      </div>
                    ) : (
                      <div className="text-red-400">
                        <FontAwesomeIcon
                          icon={faTimesCircle}
                          className="mr-2"
                        />
                        {message.message}
                      </div>
                    )}
                  </div>
                )}
                <Field
                  disabled={isSubmitting}
                  onClick={handleSubmit}
                  type="submit"
                  value={isSubmitting ? "Submitting..." : "Submit"}
                  className="bg-orange-700 hover:bg-orange-800 text-white font-bold py-4 px-4 my-4 border border-orange-700 rounded cursor-pointer"
                />
              </Form>
            )}
          </Formik>
        </Container>
      </div>
    </Wrapper>
  );
}

export default ForgotPassword;

const Container = styled.div`
  margin-top: -4rem;
  width: 400px;
  padding: 1rem;
  background: white;
  /* border-top: 2px solid ${theme.primaryColor.default}; */
`;
const Form = styled.form`
  input {
    &.error {
      border: 1px solid red;
    }
    display: block;
    width: 100%;
    border: 1px solid #ededed;
    padding: 0.2rem;
  }
  label {
    display: block;
    margin-top: 1rem;
    color: ${theme.primaryColor.default};
  }
  textarea {
    &.error {
      border: 1px solid red;
    }
    display: block;
    width: 100%;
    border: 1px solid #ededed;
    padding: 0.3rem;
  }
`;

const Wrapper = styled.div`
  background: ${theme.primaryColor.default};
  height: 100vh;
  width: 100vw;
`;

const Logo = styled.div`
  position: relative;
  z-index: 99;
  border-radius: 50%;
  margin: 10px auto;
  text-align: center;
  width: 7rem;
  height: 7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  img {
    width: 90%;
    margin: 0rem auto;
  }
`;
