import React, { useState, useEffect } from "react";
import { Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import {
  FormContainer,
  Field,
  FormFooter,
  SubmitButton,
} from "../../../../components/Form";
import { useDispatch, useSelector } from "react-redux";
import { Snackbar } from "@material-ui/core";
import api from "../../../../Services/api";
import Alert from "../../../../components/Alert";
import { useHistory } from "react-router-dom";
import { IState } from "../../../../Services/types";
import jwt from "jwt-decode";
import { setAuth } from "../../../../Services/redux/actions/auth";

function UpdateUserForm() {
  const dispatch = useDispatch();
  const history = useHistory();

  const user = useSelector((state: IState) => state.auth.user);
  const [error, setError] = useState("");
  const [snack, setSnack] = useState({
    open: false,
    message: "Profile Updated",
  });

  const [initialValues, setInitialValues] = useState({
    name: "",
    email: "",
  });

  useEffect(() => {
    setInitialValues({ ...initialValues, email: user.email, name: user.name });
  }, [user]);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
    email: Yup.string().email().required(),
  });

  const onSubmit = (
    values: IValues,
    { setSubmitting, resetForm }: FormikHelpers<IValues>
  ) => {
    setSubmitting(true);
    setError("");
    const tkn = sessionStorage.getItem("token");
    if (!tkn) {
      setError("Failed To Update Profile. Please Try Again");
      return;
    }

    const decoded: { sub: number } = jwt(tkn || "");

    api
      .updateUser(values, decoded.sub)
      .then(() => {
        dispatch(
          setAuth({ authenticated: true, user: { ...user, ...values } })
        );
        setSnack({ ...snack, open: true });
        resetForm();
      })
      .catch((e) => {
        setError("Failed To Update Profile. Please Try Again");
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <FormContainer className="shadow p-5">
      <div className="mb-4">Update Profile</div>

      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <form>
            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              open={snack.open}
              onClose={() => setSnack({ ...snack, open: false })}
              message={snack.message}
            />
            <Alert message={error} />

            <Field
              value={values.name}
              name="name"
              label="Name"
              type="text"
              onChange={handleChange}
              touched={touched.name}
              error={errors.name}
              placeholder="Enter User Name"
            ></Field>

            <Field
              value={values.email}
              name="email"
              label="Email"
              type="email"
              onChange={handleChange}
              touched={touched.email}
              error={errors.email}
              placeholder="Email"
              disabled
            ></Field>

            <div
              className="cursor-pointer my-2 text-right text-sm underline"
              onClick={() => history.push("/users/change_password")}
            >
              Change Password
            </div>
            <FormFooter>
              <SubmitButton
                isSubmitting={isSubmitting}
                handleSubmit={handleSubmit}
              />
            </FormFooter>
          </form>
        )}
      </Formik>
    </FormContainer>
  );
}

type IValues = {
  name: string;
  email: string;
};

export default UpdateUserForm;
