import axios from "axios";
import { IResources, IArticle } from "../types";

const API_ENDPOINT = process.env.REACT_APP_API;

export default {
  getProjects: () => {
    const url = `${API_ENDPOINT}/projects`;
    return axios.get(url);
  },
  getArticles: () => {
    const url = `${API_ENDPOINT}/articles`;
    return axios.get(url);
  },
  getResources: () => {
    const url = `${API_ENDPOINT}/resources`;
    return axios.get(url);
  },
  getStaff: () => {
    const url = `${API_ENDPOINT}/staff`;
    return axios.get(url);
  },
  getDonors: () => {
    const url = `${API_ENDPOINT}/partners`;
    return axios.get(url);
  },

  sendContactMessage: (payload: {
    name: string;
    email: string;
    subject: string;
    message: string;
  }) => {
    const url = `${API_ENDPOINT}/contact/contact`;
    return axios.post(url, payload);
  },

  subscribe: (payload: { name: string; email: string }) => {
    const url = `${API_ENDPOINT}/contact/subscribe`;
    return axios.post(url, payload);
  },

  login: (payload: { email: string; password: string }) => {
    const url = `${API_ENDPOINT}/auth/login`;
    return axios.post(url, payload);
  },

  getUser: (id: number, token: any) => {
    const url = `${API_ENDPOINT}/users/${id}`;
    let config = {
      headers: {
        token: token,
      },
    };
    return axios.get(url, config);
  },

  getUsers: () => {
    const url = `${API_ENDPOINT}/users`;
    let config = {
      headers: {
        token: sessionStorage.getItem("token"),
      },
    };
    return axios.get(url, config);
  },

  getMembers: () => {
    const url = `${API_ENDPOINT}/members`;
    return axios.get(url);
  },
  getMetadata: (resource: IResources) => {
    const url = `${API_ENDPOINT}/${resource}`;
    return axios.get(url);
  },
  fetchDetails: () => {
    let config = {
      headers: {
        token: sessionStorage.getItem("token"),
      },
    };
    const url = `${API_ENDPOINT}/details`;
    return axios.get(url);
  },

  updateDetails: (data: any) => {
    let config = {
      headers: {
        token: sessionStorage.getItem("token"),
      },
    };
    const url = `${API_ENDPOINT}/details`;
    return axios.post(url, data, config);
  },
  addArticle: (payload: {
    title: string;
    body: string;
    picture: string;
    authors: string;
    picture_code: string;
    type_id: number;
  }) => {
    const url = `${API_ENDPOINT}/articles`;

    let config = {
      headers: {
        token: sessionStorage.getItem("token"),
      },
    };
    return axios.post(url, payload, config);
  },

  updateArticle: (
    payload: {
      title: string;
      body: string;
      picture: string;
      authors: string;
      type_id: number;
      picture_code: string;
    },
    id: number
  ) => {
    const url = `${API_ENDPOINT}/articles/${id}`;

    let config = {
      headers: {
        token: sessionStorage.getItem("token"),
      },
    };
    return axios.put(url, payload, config);
  },

  addProject: (payload: {
    title: string;
    about: string;
    picture: string;
    status_id: number;
    picture_code: string;
  }) => {
    const url = `${API_ENDPOINT}/projects`;

    let config = {
      headers: {
        token: sessionStorage.getItem("token"),
      },
    };
    return axios.post(url, payload, config);
  },

  updateProject: (
    payload: {
      title: string;
      about: string;
      picture: string;
      status_id: number;
      picture_code: string;
    },
    id: number
  ) => {
    const url = `${API_ENDPOINT}/projects/${id}`;

    let config = {
      headers: {
        token: sessionStorage.getItem("token"),
      },
    };
    return axios.put(url, payload, config);
  },

  addStaff: (payload: {
    name: string;
    picture: string;
    picture_code: string;
    position: string;
    email: string;
    phone: string;
    type_id: number;
  }) => {
    const url = `${API_ENDPOINT}/staff`;

    let config = {
      headers: {
        token: sessionStorage.getItem("token"),
      },
    };
    return axios.post(url, payload, config);
  },

  updateStaff: (
    payload: {
      name: string;
      picture: string;
      position: string;
      email: string;
      phone: string;
      type_id: number;
      picture_code: string;
    },
    id: number
  ) => {
    const url = `${API_ENDPOINT}/staff/${id}`;

    let config = {
      headers: {
        token: sessionStorage.getItem("token"),
      },
    };
    return axios.put(url, payload, config);
  },

  addMember: (payload: any) => {
    const url = `${API_ENDPOINT}/members`;

    let config = {
      headers: {
        token: sessionStorage.getItem("token"),
      },
    };
    return axios.post(url, payload, config);
  },

  updateMember: (payload: any, id: number) => {
    const url = `${API_ENDPOINT}/members/${id}`;

    let config = {
      headers: {
        token: sessionStorage.getItem("token"),
      },
    };
    return axios.put(url, payload, config);
  },

  addPartner: (payload: {
    name: string;
    picture: string;
    website: string;
    picture_code: string;
  }) => {
    const url = `${API_ENDPOINT}/partners`;

    let config = {
      headers: {
        token: sessionStorage.getItem("token"),
      },
    };
    return axios.post(url, payload, config);
  },

  updatePartner: (
    payload: {
      name: string;
      picture: string;
      website: string;
      picture_code: string;
    },
    id: number
  ) => {
    const url = `${API_ENDPOINT}/partners/${id}`;

    let config = {
      headers: {
        token: sessionStorage.getItem("token"),
      },
    };
    return axios.put(url, payload, config);
  },

  addUser: (payload: {
    name: string;
    email: string;
    password: string;
    confirm_password: string;
  }) => {
    const url = `${API_ENDPOINT}/auth/register`;

    let config = {
      headers: {
        token: sessionStorage.getItem("token"),
      },
    };
    return axios.post(url, payload, config);
  },

  changePassword: (payload: {
    email: string;
    old_password: string;
    password: string;
    confirm_password: string;
  }) => {
    const url = `${API_ENDPOINT}/auth/change_password`;

    let config = {
      headers: {
        token: sessionStorage.getItem("token"),
      },
    };
    return axios.put(url, payload, config);
  },

  updateUser: (
    payload: {
      name: string;
    },
    id: number
  ) => {
    const url = `${API_ENDPOINT}/users/${id}`;

    let config = {
      headers: {
        token: sessionStorage.getItem("token"),
      },
    };
    return axios.put(url, payload, config);
  },

  addResource: (payload: {
    name: string;
    path: string;
    type_id: number;
    code: string | null;
  }) => {
    const url = `${API_ENDPOINT}/resources`;

    let config = {
      headers: {
        token: sessionStorage.getItem("token"),
      },
    };
    return axios.post(url, payload, config);
  },
  updateResource: (
    payload: {
      name: string;
      path: string;
      type_id: number;
      code: string | null;
    },
    id: number
  ) => {
    const url = `${API_ENDPOINT}/resources/${id}`;

    let config = {
      headers: {
        token: sessionStorage.getItem("token"),
      },
    };
    return axios.put(url, payload, config);
  },

  forgotPassword: (payload: { email: string }) => {
    const url = `${API_ENDPOINT}/auth/forgot_password`;
    return axios.post(url, payload);
  },

  resetPassword: (payload: {
    email: string;
    password: string;
    confirm_password: string;
    token: string;
  }) => {
    const url = `${API_ENDPOINT}/auth/reset_password`;
    return axios.post(url, payload);
  },

  deleteResource: (
    resource:
      | "articles"
      | "projects"
      | "staff"
      | "partners"
      | "resources"
      | "users"
      | "members",
    id: number
  ) => {
    const url = `${API_ENDPOINT}/${resource}/${id}`;

    let config = {
      headers: {
        token: sessionStorage.getItem("token"),
      },
    };
    return axios.delete(url, config);
  },
};
