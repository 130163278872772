import React from "react";

import { useSelector, useDispatch } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import MaterialTable from "material-table";
import { useHistory } from "react-router-dom";
import swal from "sweetalert2";
import { IState } from "../../../../../Services/types";
import { getResources } from "../../../../../Services/redux/actions/data";
import api from "../../../../../Services/api";

function DocsList(props: {
  isNews?: boolean;
  isMembership?: boolean;
  isMembers?: boolean;
}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const resources = useSelector((state: IState) => state.data.resources);
  const docs = props.isNews
    ? resources.filter(
        (r) =>
          r.type.type === "Doc" && r.name.toLowerCase().includes("newsletter")
      )
    : props.isMembership
    ? resources.filter((r) => r.type.type === "Membership")
    : props.isMembers
    ? resources.filter((r) => r.type.type === "MembersList")
    : resources.filter((r) => r.type.type === "Doc");
  const data = {
    columnDefs: [
      { title: "Name", field: "name" },
      { title: "Created", field: "created_at" },
    ],
    rowData: docs,
    actions: [
      {
        icon: () => (
          <FontAwesomeIcon
            icon={faEdit}
            className="text-base text-orange-400"
          />
        ),
        tooltip: "Edit Resource",
        // @ts-ignore
        onClick: (event, rowData) =>
          history.push(`/resources/edit/${rowData?.id}/Doc`),
      },
      {
        icon: () => (
          <FontAwesomeIcon icon={faTrash} className="text-base text-red-600" />
        ),
        tooltip: "Delete Resource",
        // @ts-ignore
        onClick: (event, rowData) => onDelete(rowData?.id),
      },
    ],
    options: {
      actionsColumnIndex: -1,
    },
  };

  const onDelete = (id: number) => {
    swal
      .fire({
        type: "warning",
        title: "Are you sure?",
        text: "You will not be able to recover this record!",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, keep it",
      })
      .then((result: any) => {
        if (result.value) {
          api
            .deleteResource("resources", id)
            .then(() => {
              swal.fire("Deleted!", "Resource deleted.", "success");
              dispatch(getResources());
            })
            .catch(() => {
              swal.fire(
                "Failed to delete",
                "Failed to delete resource.",
                "error"
              );
            });

          return;
        }
      });
  };
  return (
    <MaterialTable
      onRowClick={(e, v) => window.open(v?.path, "_blank")}
      style={{ padding: "1rem" }}
      title="Docs"
      columns={data.columnDefs}
      data={data.rowData}
      actions={data.actions}
      options={data.options}
    />
  );
}

export default DocsList;
