import React from "react";
import PageContainer from "../../../components/PageContainer";
import StaffForm from "./components/Form";

function CreateStaff() {
  return (
    <PageContainer>
      <StaffForm></StaffForm>
    </PageContainer>
  );
}

export default CreateStaff;
