import React from "react";
import PageContainer from "../../../components/PageContainer";
import { useSelector } from "react-redux";
import { IState } from "../../../Services/types";
import { Grid } from "@material-ui/core";
import StaffCard from "./components/StaffCard";

function StaffList() {
  const staff = useSelector((state: IState) => state.data.staff);
  return (
    <PageContainer addBtn>
      <Grid container justify="center" spacing={4}>
        {staff.map((st) => (
          <Grid key={st.id} item xs={12} sm={6} md={3} lg={2}>
            <StaffCard staff={st} />
          </Grid>
        ))}
      </Grid>
    </PageContainer>
  );
}

export default StaffList;
