import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
// @ts-ignore
import styled from "styled-components";

import { Formik, Field, ErrorMessage, FormikHelpers } from "formik";
import * as Yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import logo from "../../../csona.png";
import { useDispatch } from "react-redux";
import swal from "sweetalert2";
import { useHistory, useParams } from "react-router-dom";
import api from "../../../Services/api";
import { theme } from "../../../theme";

function PasswordReset() {
  const [message, setMessage] = useState({ type: "success", message: "" });
  const history = useHistory();
  const params: { token: string } = useParams();
  useEffect(() => {
    setInitialValues({ ...initialValues, token: params.token });
  }, [history.location.pathname]);

  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Required"),
    password: Yup.string().required().min(5),
    confirm_password: Yup.string()
      .required()
      .oneOf([Yup.ref("password")], "Passwords do not match"),
  });

  const [initialValues, setInitialValues] = useState({
    email: "",
    password: "",
    confirm_password: "",
    token: "",
  });
  const onSubmit = (
    values: {
      email: string;
      password: string;
      confirm_password: string;
      token: string;
    },
    {
      setSubmitting,
      setFieldValue,
    }: FormikHelpers<{
      email: string;
      password: string;
      confirm_password: string;
      token: string;
    }>
  ) => {
    setSubmitting(true);
    setMessage({
      type: "none",
      message: "",
    });
    api
      .resetPassword(values)
      .then(() => {
        swal.fire("Password Reset!", "", "success");
        history.push("/");
      })
      .catch(() => {
        setFieldValue("password", "");
        setFieldValue("confirm_password", "");
        setMessage({
          type: "error",
          message: "Failed to reset password",
        });
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <Wrapper className="flex justify-center pt-20">
      <div>
        <Logo>
          <img src={logo}></img>
        </Logo>
        <Container className="text-base shadow">
          <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleSubmit,
              isSubmitting,
            }) => (
              <Form className="m-4">
                <label>Email</label>
                <Field
                  className={touched.email && errors.email ? "error" : ""}
                  type="text"
                  id="email"
                  name="email"
                  onChange={handleChange}
                  value={values.email}
                  placeholder="Enter Your Email"
                  required
                />
                <div className="text-red-400 text-sm">
                  <ErrorMessage name="email" />
                </div>
                <label>Password</label>
                <Field
                  className={touched.password && errors.password ? "error" : ""}
                  type="password"
                  id="password"
                  name="password"
                  onChange={handleChange}
                  value={values.password}
                  placeholder="Password"
                  required
                />
                <div className="text-red-400 text-sm">
                  <ErrorMessage name="password" />
                </div>
                <label>Confirm Password</label>
                <Field
                  className={
                    touched.confirm_password && errors.confirm_password
                      ? "error"
                      : ""
                  }
                  type="password"
                  id="confirm_password"
                  name="confirm_password"
                  onChange={handleChange}
                  value={values.confirm_password}
                  placeholder="Confirm Password"
                  required
                />
                <div className="text-red-400 text-sm">
                  <ErrorMessage name="confirm_password" />
                </div>
                <input name="token" type="hidden" value={values.token}></input>
                {message.message.length > 0 && (
                  <div className="text-base text-center mt-2">
                    {message.type === "success" ? (
                      <div className="text-green-400">
                        <FontAwesomeIcon
                          icon={faCheckCircle}
                          className="mr-2"
                        />
                        {message.message}
                      </div>
                    ) : (
                      <div className="text-red-400">
                        <FontAwesomeIcon
                          icon={faTimesCircle}
                          className="mr-2"
                        />
                        {message.message}
                      </div>
                    )}
                  </div>
                )}
                <Field
                  disabled={isSubmitting}
                  onClick={handleSubmit}
                  type="submit"
                  value={isSubmitting ? "Requesting..." : "Change Password"}
                  className="bg-orange-700 hover:bg-orange-800 text-white font-bold py-4 px-4 my-4 border border-orange-700 rounded cursor-pointer"
                />
              </Form>
            )}
          </Formik>
        </Container>
      </div>
    </Wrapper>
  );
}

export default PasswordReset;

const Container = styled.div`
  margin-top: -4rem;
  width: 400px;
  padding: 1rem;
  background: white;
  /* border-top: 2px solid ${theme.primaryColor.default}; */
`;
const Form = styled.form`
  input {
    &.error {
      border: 1px solid red;
    }
    display: block;
    width: 100%;
    border: 1px solid #ededed;
    padding: 0.2rem;
  }
  label {
    display: block;
    margin-top: 1rem;
    color: ${theme.primaryColor.default};
  }
  textarea {
    &.error {
      border: 1px solid red;
    }
    display: block;
    width: 100%;
    border: 1px solid #ededed;
    padding: 0.3rem;
  }
`;

const Wrapper = styled.div`
  background: ${theme.primaryColor.default};
  height: 100vh;
  width: 100vw;
`;

const Logo = styled.div`
  position: relative;
  z-index: 99;
  border-radius: 50%;
  margin: 10px auto;
  text-align: center;
  width: 7rem;
  height: 7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  img {
    width: 90%;
    margin: 0rem auto;
  }
`;
