import React from "react";
import PageContainer from "../../../components/PageContainer";
import { useSelector, useDispatch } from "react-redux";
import { IState } from "../../../Services/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import MaterialTable from "material-table";
import { ellipses } from "../../../Services/helpers";
import { useHistory } from "react-router-dom";
import swal from "sweetalert2";
import api from "../../../Services/api";
import { getArticles } from "../../../Services/redux/actions/data";

function ArticlesList() {
  const history = useHistory();

  const dispatch = useDispatch();

  const articles = useSelector((state: IState) => state.data.articles);
  const data = {
    columnDefs: [
      { title: "Title", field: "title" },
      { title: "Body", field: "body" },
      { title: "Type", field: "type" },
      { title: "Created", field: "created_at" },
    ],
    rowData: articles.map((a) => ({
      ...a,
      body: ellipses(a.body),
      type: a.type.type,
    })),
    actions: [
      {
        icon: () => (
          <FontAwesomeIcon
            icon={faEdit}
            className="text-base text-orange-400"
          />
        ),
        tooltip: "Edit Article",
        // @ts-ignore
        onClick: (event, rowData) =>
          history.push(`/articles/edit/${rowData?.id}`),
      },
      {
        icon: () => (
          <FontAwesomeIcon icon={faTrash} className="text-base text-red-600" />
        ),
        tooltip: "Delete Article",
        // @ts-ignore
        onClick: (event, rowData) => onDelete(rowData?.id),
      },
    ],
    options: {
      actionsColumnIndex: -1,
    },
  };

  const onDelete = (id: number) => {
    swal
      .fire({
        type: "warning",
        title: "Are you sure?",
        text: "You will not be able to recover this record!",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, keep it",
      })
      .then((result: any) => {
        if (result.value) {
          api
            .deleteResource("articles", id)
            .then(() => {
              dispatch(getArticles());
              swal.fire("Deleted!", "Article deleted.", "success");
            })
            .catch(() => {
              swal.fire(
                "Failed to delete",
                "Failed to delete article.",
                "error"
              );
            });

          return;
        }
      });
  };

  return (
    <PageContainer addBtn>
      <MaterialTable
        onRowClick={(e, v) => history.push(`/articles/${v?.id}`)}
        style={{ padding: "1rem" }}
        title="Articles"
        columns={data.columnDefs}
        data={data.rowData}
        actions={data.actions}
        options={data.options}
      />
    </PageContainer>
  );
}

export default ArticlesList;
