import actions from "./actions";
import { IResources } from "../../types";
import api from "../../api";

export const getMeta = (payload: IResources) => {
  return {
    type: actions.getMeta,
    payload: api.getMetadata(payload),
    meta: payload,
  };
};
