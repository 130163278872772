import React from "react";
import TopBar from "./TopBar";
import SideBar from "./SideBar";

function NavBar() {
  return (
    <div>
      <TopBar />
      <SideBar />
    </div>
  );
}

export default NavBar;
