import React, { useState, useEffect } from "react";
import { Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import {
  FormContainer,
  Field,
  FormFooter,
  SubmitButton,
  RadioGroup,
  CloudinaryField,
} from "../../../../components/Form";
import { useSelector, useDispatch } from "react-redux";
import { IState } from "../../../../Services/types";
import { Snackbar } from "@material-ui/core";
import api from "../../../../Services/api";
import Alert from "../../../../components/Alert";
import { getStaff } from "../../../../Services/redux/actions/data";
import { useParams } from "react-router-dom";

function StaffForm({ update }: Props) {
  const params = useParams();

  const staffType = useSelector((state: IState) => state.meta.staff_types);

  const staff = useSelector((state: IState) => state.data.staff);

  const dispatch = useDispatch();

  const [error, setError] = useState("");

  const [snack, setSnack] = useState({ open: false, message: "Member Saved" });

  const [initialValues, setinitialValues] = useState({
    name: "",
    picture:
      "https://res.cloudinary.com/csona/image/upload/v1589793608/Profile_avatar_placeholder_large_g12xp0.png",
    picture_code: "",
    position: "",
    email: "",
    phone: "",
    type_id: 0,
  });

  useEffect(() => {
    if (update) {
      // @ts-ignore
      const s = staff.find((s) => s.id === Number(params.id));
      if (s)
        setinitialValues({
          name: s.name,
          picture: s.picture.path,
          picture_code: s.picture.code,
          position: s.position,
          email: s.email,
          phone: s.phone,
          type_id: s.type_id,
        });
      return;
    }
    setinitialValues({
      ...initialValues,
      type_id: staffType.length > 0 ? staffType[0].id : 0,
    });
  }, [staffType, params, staff]);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
    picture: Yup.string().required(),
  });

  const onSuccess = (resetForm: Function) => {
    dispatch(getStaff());
    setSnack({ ...snack, open: true });
    resetForm();
  };

  const onSubmit = (
    values: IValues,
    { setSubmitting, resetForm }: FormikHelpers<IValues>
  ) => {
    setSubmitting(true);
    setError("");
    if (update) {
      api
        // @ts-ignore
        .updateStaff(values, params.id)
        .then(() => {
          onSuccess(resetForm);
        })
        .catch((e) => {
          setError("Failed To Update Staff. Please Try Again");
        })
        .finally(() => {
          setSubmitting(false);
        });
      return;
    }
    api
      .addStaff(values)
      .then(() => {
        onSuccess(resetForm);
      })
      .catch((e) => {
        setError("Failed To Add Staff. Please Try Again");
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <FormContainer className="shadow p-5">
      <div className="mb-4">{update ? "Update Staff" : "New Staff"}</div>

      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <form>
            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              open={snack.open}
              onClose={() => setSnack({ ...snack, open: false })}
              message={snack.message}
            />
            <Alert message={error} />
            <CloudinaryField
              width="20%"
              name="picture"
              values={values}
              setFieldValue={setFieldValue}
            />

            <RadioGroup
              label="Type"
              name="type_id"
              values={values}
              setFieldValue={setFieldValue}
              displayField="type"
              options={staffType}
            />

            <Field
              value={values.name}
              name="name"
              label="Name"
              type="text"
              onChange={handleChange}
              touched={touched.name}
              error={errors.name}
              placeholder="Enter Staff Fullname"
            ></Field>

            <Field
              value={values.position}
              name="position"
              label="Position"
              type="text"
              onChange={handleChange}
              touched={touched.position}
              error={errors.position}
              placeholder="Enter Staff Position (CEO etc)"
            ></Field>

            <Field
              value={values.email}
              name="email"
              label="Email"
              type="text"
              onChange={handleChange}
              touched={touched.email}
              error={errors.email}
              placeholder="Enter Staff Email"
            ></Field>

            <Field
              value={values.phone}
              name="phone"
              label="Phone"
              type="text"
              onChange={handleChange}
              touched={touched.phone}
              error={errors.phone}
              placeholder="Enter Staff Phone Number"
            ></Field>
            <FormFooter>
              <SubmitButton
                isSubmitting={isSubmitting}
                handleSubmit={handleSubmit}
              />
            </FormFooter>
          </form>
        )}
      </Formik>
    </FormContainer>
  );
}

type Props = {
  update?: boolean;
};
type IValues = {
  name: string;
  picture: string;
  picture_code: string;
  position: string;
  email: string;
  phone: string;
  type_id: number;
};

export default StaffForm;
