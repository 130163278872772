import React from "react";
import PageContainer from "../../../components/PageContainer";
import { useSelector, useDispatch } from "react-redux";
import { IState } from "../../../Services/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import MaterialTable from "material-table";
import { ellipses } from "../../../Services/helpers";
import { useHistory } from "react-router-dom";
import swal from "sweetalert2";
import { getProjects } from "../../../Services/redux/actions/data";
import api from "../../../Services/api";

function ProjectsList() {
  const history = useHistory();
  const dispatch = useDispatch();
  const projects = useSelector((state: IState) => state.data.projects);
  const data = {
    columnDefs: [
      { title: "Title", field: "title" },
      { title: "About", field: "about" },
      { title: "Status", field: "status" },
      { title: "Created", field: "created_at" },
    ],
    rowData: projects.map((a) => ({
      ...a,
      about: ellipses(a.about),
      status: a.status.status,
    })),
    actions: [
      {
        icon: () => (
          <FontAwesomeIcon
            icon={faEdit}
            className="text-base text-orange-400"
          />
        ),
        tooltip: "Edit Project",
        // @ts-ignore
        onClick: (event, rowData) =>
          history.push(`/projects/edit/${rowData?.id}`),
      },
      {
        icon: () => (
          <FontAwesomeIcon icon={faTrash} className="text-base text-red-600" />
        ),
        tooltip: "Delete Project",
        // @ts-ignore
        onClick: (event, rowData) => onDelete(rowData?.id),
      },
    ],
    options: {
      actionsColumnIndex: -1,
    },
  };

  const onDelete = (id: number) => {
    swal
      .fire({
        type: "warning",
        title: "Are you sure?",
        text: "You will not be able to recover this record!",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, keep it",
      })
      .then((result: any) => {
        if (result.value) {
          api
            .deleteResource("projects", id)
            .then(() => {
              swal.fire("Deleted!", "Project deleted.", "success");
              dispatch(getProjects());
            })
            .catch(() => {
              swal.fire(
                "Failed to delete",
                "Failed to delete project.",
                "error"
              );
            });

          return;
        }
      });
  };
  return (
    <PageContainer addBtn>
      <MaterialTable
        onRowClick={(e, v) => history.push(`/projects/${v?.id}`)}
        style={{ padding: "1rem" }}
        title="Projects"
        columns={data.columnDefs}
        data={data.rowData}
        actions={data.actions}
        options={data.options}
      />
    </PageContainer>
  );
}

export default ProjectsList;
