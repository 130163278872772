import React from "react";
import PageContainer from "../../../components/PageContainer";
import ChangePasswordForm from "./components/ChangePasswordForm";

function ChangePassword() {
  return (
    <PageContainer>
      <ChangePasswordForm></ChangePasswordForm>
    </PageContainer>
  );
}

export default ChangePassword;
