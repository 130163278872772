import React from "react";
import { useSelector } from "react-redux";
import { IState } from "../../../../../Services/types";
import { Grid } from "@material-ui/core";
import ImageCard from "./ImageCard";

function Images() {
  const resources = useSelector((state: IState) => state.data.resources);
  const images = resources.filter((r) => r.type.type === "Image");
  return (
    <Grid container justify="center" spacing={4}>
      {images.map((i) => (
        <Grid key={i.id} item xs={12} sm={6} md={3} lg={3}>
          <ImageCard resource={i} />
        </Grid>
      ))}
    </Grid>
  );
}

export default Images;
