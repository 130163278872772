import React from "react";
import Card from "../../../../components/Card";
import { IStaff } from "../../../../Services/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import swal from "sweetalert2";
import api from "../../../../Services/api";
import { getStaff } from "../../../../Services/redux/actions/data";

function StaffCard({ staff }: Props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const onDelete = (id: number) => {
    swal
      .fire({
        type: "warning",
        title: "Are you sure?",
        text: "You will not be able to recover this record!",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, keep it",
      })
      .then((result: any) => {
        if (result.value) {
          api
            .deleteResource("staff", id)
            .then(() => {
              swal.fire("Deleted!", "Staff deleted.", "success");
              dispatch(getStaff());
            })
            .catch(() => {
              swal.fire("Failed to delete", "Failed to delete staff.", "error");
            });

          return;
        }
      });
  };
  return (
    <Card image={staff.picture.path} height={20} shadow>
      <div className="text-sm p-4">
        <strong>{staff.name}</strong>
        <div className="text-orange-600">{staff.type.type}</div>
        <div>{staff.position}</div>
        <div className="flex my-6 justify-center items-center">
          <FontAwesomeIcon
            onClick={() => history.push(`/staff/edit/${staff.id}`)}
            icon={faEdit}
            className="text-orange-400 cursor-pointer"
          />
          <FontAwesomeIcon
            onClick={() => onDelete(staff.id)}
            icon={faTrash}
            className="text-red-400 ml-4 cursor-pointer"
          />
        </div>
      </div>
    </Card>
  );
}

type Props = {
  staff: IStaff;
};
export default StaffCard;
