import actions from "../actions/actions";
import { IData, IDetails } from "../../types";
import ApiAdapter from "../../api/apiAdaptor";

const initialState = {
  projects: [],
  articles: [],
  resources: [],
  users: [],
  staff: [],
  donors: [],
  members: [],
  details: {} as IDetails,
} as IData;

export default (
  state = initialState as IData,
  action: { type: string; payload?: any; meta?: any }
) => {
  switch (action.type) {
    case actions.getProjects + "_FULFILLED":
      return {
        ...state,
        projects: ApiAdapter.sortResource(action.payload.data || []),
      };

    case actions.getArticles + "_FULFILLED":
      return {
        ...state,
        articles: ApiAdapter.sortResource(action.payload.data || []),
      };

    case actions.getResources + "_FULFILLED":
      return {
        ...state,
        resources: ApiAdapter.sortResource(action.payload.data || []),
      };

    case actions.getUsers + "_FULFILLED":
      return {
        ...state,
        users: ApiAdapter.sortResource(action.payload.data || []),
      };

    case actions.getMembers + "_FULFILLED":
      return {
        ...state,
        members: ApiAdapter.sortResource(action.payload.data || []),
      };

    case actions.getStaff + "_FULFILLED":
      return {
        ...state,
        staff: action.payload.data || [],
      };

    case actions.getDetails + "_FULFILLED":
      return {
        ...state,
        details: action.payload.data,
      };

    case actions.getDonors + "_FULFILLED":
      return {
        ...state,
        donors: ApiAdapter.sortResource(action.payload.data || []),
      };
    default:
      return state;
  }
};
