import React, { useState, useEffect } from "react";
import { IArticle, IState } from "../../../Services/types";
import { Dialog, Modal } from "@material-ui/core";
import { LazyLoadImage } from "react-lazy-load-image-component";
// @ts-ignore
import styled from "styled-components";
import PageContainer from "../../../components/PageContainer";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { theme } from "../../../theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import Parser from "html-react-parser";

function ViewArticle() {
  const [imageLoaded, setImageLoaded] = useState(false);
  const params = useParams();
  const articles = useSelector((state: IState) => state.data.articles);
  const [article, setArticle] = useState({} as IArticle);
  useEffect(() => {
    // @ts-ignore
    const a = articles.find((a) => a.id === Number(params.id));
    if (a) setArticle(a);
  }, [params, articles]);
  return (
    <PageContainer addBtn>
      <Img>
        {!imageLoaded && (
          <img src="https://res.cloudinary.com/csona/image/upload/v1591092657/placeholder-image10_om6ciy.jpg" />
        )}
        <img
          className={imageLoaded ? "" : "hidden"}
          src={article?.picture?.path}
          onLoad={() => setImageLoaded(true)}
        />
      </Img>
      <Title className="my-2">
        <span>{article?.type?.type} | </span>
        {article?.title}
      </Title>
      <DateCreated>
        <i>{article.authors} </i> <span className="m-2">|</span>
        <FontAwesomeIcon icon={faClock} className="mr-2" />
        {moment(article?.created_at).fromNow()}
      </DateCreated>
      <p className="my-3 ql-editor">{article && Parser(article.body || "")}</p>
    </PageContainer>
  );
}

export default ViewArticle;

const Img = styled.div`
  width: 50%;
`;

const Title = styled.div`
  color: ${theme.primaryColor.default};
  span {
    color: gray;
  }
`;

const DateCreated = styled.div`
  font-size: 0.8rem;
  color: gray;
`;
