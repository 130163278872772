import React from "react";
import PageContainer from "../../../../../../components/PageContainer";

import SliderForm from "./components/Form";

function CreateSlider() {
  return (
    <PageContainer>
      <SliderForm></SliderForm>
    </PageContainer>
  );
}

export default CreateSlider;
