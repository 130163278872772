import React from "react";
// @ts-ignore
import styled from "styled-components";
import { theme } from "../../theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faNewspaper,
  faProjectDiagram,
  faUsers,
  faTachometerAlt,
  faDatabase,
  faFile,
  faHandshake,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useHistory } from "react-router-dom";
import logo from "../../csona.png";

export const menu = [
  {
    name: "Dashboard",
    url: "/dashboard",
    icon: faTachometerAlt,
  },
  {
    name: "Articles",
    url: "/articles",
    icon: faNewspaper,
  },
  {
    name: "Projects",
    url: "/projects",
    icon: faProjectDiagram,
  },
  {
    name: "Staff",
    url: "/staff",
    icon: faUsers,
  },
  {
    name: "Members",
    url: "/members",
    icon: faHandshake,
  },
  {
    name: "Partners",
    url: "/partners",
    icon: faDatabase,
  },
  {
    name: "Resources",
    url: "/resources",
    icon: faFile,
  },
  {
    name: "Users",
    url: "/users",
    icon: faUsers,
  },
] as Array<menuItem>;
function SideBar() {
  const history = useHistory();
  const page = history.location.pathname.split("/")[1];

  return (
    <Wrapper className="shadow">
      <Link to="/">
        <Logo>
          <img src={logo}></img>
        </Logo>
      </Link>
      {menu.map((m) => (
        <Link key={m.url} to={m.url}>
          <MenuItem
            className="py-2 px-4 flex items-center"
            active={page === m.url.split("/")[1]}
          >
            <div className="px-4">
              <FontAwesomeIcon icon={m.icon} />
            </div>
            <div>{m.name}</div>
          </MenuItem>
        </Link>
      ))}
    </Wrapper>
  );
}

type menuItem = {
  name: string;
  url: string;
  icon: any;
};
export default SideBar;

const Logo = styled.div`
  border-radius: 50%;
  margin: 20px auto;
  text-align: center;
  width: 8rem;
  height: 8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  img {
    width: 90%;
    margin: 0rem auto;
  }
`;
const Wrapper = styled.div`
  z-index: 1000;
  padding-top: 1rem;
  position: fixed;
  width: 17rem;
  top: 0;
  left: 0;
  min-height: 100vh;
  background: ${theme.primaryColor.darker};
`;

const MenuItem = styled("div")`
  cursor: pointer;
  color: ${(props: any) => (props.active ? "#f2cf4f" : "white")};
  border-left: 5px solid
    ${(props: any) => (props.active ? "#f2cf4f" : theme.primaryColor.darker)};
`;
