import React, { useState } from "react";
import PageContainer from "../../../components/PageContainer";
import { AppBar, Tabs, Tab } from "@material-ui/core";
import Images from "./component/Images";
import DocsList from "./component/Docs";

function ResourcesList() {
  const [panel, setpanel] = useState(0);
  const handleChange = (e: any, value: any) => {
    setpanel(value);
  };
  return (
    <PageContainer addBtn>
      <AppBar position="static" color="inherit">
        <Tabs
          value={panel}
          onChange={handleChange}
          aria-label="Resources"
          indicatorColor="primary"
        >
          <Tab label="Images" />
          {/* <Tab label="Videos" /> */}
          <Tab label="Docs" />
          <Tab label="Newsletters" />
          <Tab label="Membership Docs" />
          <Tab label="Members List" />
        </Tabs>
      </AppBar>
      <TabPanel index={0} active={panel === 0}>
        <Images />
      </TabPanel>
      {/* <TabPanel index={1} active={panel === 1}>
        Item twoo
      </TabPanel> */}
      <TabPanel index={1} active={panel === 1}>
        <DocsList />
      </TabPanel>
      <TabPanel index={2} active={panel === 2}>
        <DocsList isNews />
      </TabPanel>
      <TabPanel index={3} active={panel === 3}>
        <DocsList isMembership />
      </TabPanel>
      <TabPanel index={4} active={panel === 4}>
        <DocsList isMembers />
      </TabPanel>
    </PageContainer>
  );
}

export default ResourcesList;

const TabPanel = ({
  active,
  index,
  children,
}: {
  active: boolean;
  index: number;
  children: any;
}) => {
  return (
    <div
      className="shadow p-5"
      role="tabpanel"
      hidden={!active}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
    >
      {children}
    </div>
  );
};
