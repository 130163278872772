import React from "react";
import PageContainer from "../../../components/PageContainer";
import UsersForm from "./components/Form";

function CreateUser() {
  return (
    <PageContainer>
      <UsersForm></UsersForm>
    </PageContainer>
  );
}

export default CreateUser;
