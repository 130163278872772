import React from "react";
import PageContainer from "../../../components/PageContainer";
import PartnerForm from "../Create/components/Form";

function UpdatePartner() {
  return (
    <PageContainer addBtn>
      <PartnerForm update />
    </PageContainer>
  );
}

export default UpdatePartner;
