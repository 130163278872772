import React from "react";
import PageContainer from "../../../components/PageContainer";
import { useSelector } from "react-redux";
import { IState } from "../../../Services/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import MaterialTable from "material-table";
import { ellipses } from "../../../Services/helpers";
import { useHistory } from "react-router-dom";
import swal from "sweetalert2";
import { Grid } from "@material-ui/core";
import PartnerCard from "./components/PartnerCard";

function PartnersList() {
  const history = useHistory();
  const partners = useSelector((state: IState) => state.data.donors);
  const data = {
    columnDefs: [
      { title: "Name", field: "name" },
      { title: "Website", field: "website" },
    ],
    rowData: partners,
    actions: [
      {
        icon: () => (
          <FontAwesomeIcon
            icon={faEdit}
            className="text-base text-orange-400"
          />
        ),
        tooltip: "Edit Partner",
        // @ts-ignore
        onClick: (event, rowData) =>
          history.push(`/partners/edit/${rowData?.id}`),
      },
      {
        icon: () => (
          <FontAwesomeIcon icon={faTrash} className="text-base text-red-600" />
        ),
        tooltip: "Delete Partner",
        // @ts-ignore
        onClick: (event, rowData) => onDelete(),
      },
    ],
    options: {
      actionsColumnIndex: -1,
    },
  };

  const onDelete = () => {
    swal
      .fire({
        type: "warning",
        title: "Are you sure?",
        text: "You will not be able to recover this record!",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, keep it",
      })
      .then((result: any) => {
        if (result.value) {
          swal.fire(
            "Deleted!",
            "Your imaginary file has been deleted.",
            "success"
          );
          return;
        }
      });
  };
  return (
    <PageContainer addBtn>
      <Grid container justify="center" spacing={4}>
        {partners.map((pt) => (
          <Grid key={pt.id} item xs={12} sm={6} md={3} lg={2}>
            <PartnerCard partner={pt} />
          </Grid>
        ))}
      </Grid>
    </PageContainer>
  );
}

export default PartnersList;
