import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import Card from "../../../../../components/Card";
import { IResource } from "../../../../../Services/types";
import { useHistory } from "react-router-dom";
import swal from "sweetalert2";
import api from "../../../../../Services/api";
import { getResources } from "../../../../../Services/redux/actions/data";
import { useDispatch } from "react-redux";

function ImageCard({ resource }: Props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const onDelete = (id: number) => {
    swal
      .fire({
        type: "warning",
        title: "Are you sure?",
        text: "You will not be able to recover this record!",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, keep it",
      })
      .then((result: any) => {
        if (result.value) {
          api
            .deleteResource("resources", id)
            .then(() => {
              swal.fire("Deleted!", "Resource deleted.", "success");
              dispatch(getResources());
            })
            .catch(() => {
              swal.fire(
                "Failed to delete",
                "Failed to delete resource.",
                "error"
              );
            });

          return;
        }
      });
  };

  return (
    <Card image={resource.path} height={20} shadow>
      <div className="text-sm p-4">
        <strong>{resource.name}</strong>
        <div className="flex my-6 justify-center items-center">
          <FontAwesomeIcon
            onClick={() => history.push(`/resources/edit/${resource.id}/Image`)}
            icon={faEdit}
            className="text-orange-400 cursor-pointer"
          />
          <FontAwesomeIcon
            onClick={() => onDelete(resource.id)}
            icon={faTrash}
            className="text-red-400 ml-4 cursor-pointer"
          />
        </div>
      </div>
    </Card>
  );
}

type Props = {
  resource: IResource;
};
export default ImageCard;
